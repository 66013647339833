import { React, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { CircularProgress } from "@mui/material";
import MDButton from "../../../components/MDButton";
import MDAlert from '../../../components/MDAlert';
import Cookies from 'universal-cookie';
import moment from 'moment';
function ForecastDownload() {

    const cookies = new Cookies();
    const [buffer, setBuffer] = useState(false);
    const [error, setError] = useState(null);
    const csvLink = useRef();
    const [csvReport, setCsvReport] = useState({ data: [], headers: [], filename: 'fabric_forecast_open.csv' });

    const downloadHandler = async () => {
        setBuffer(true);
        let url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/forecast/get`;
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-type": "application/json",
                "access-token": await cookies.get("jwt"),
                "refresh-token": await cookies.get('refresh')
            

            },
        }

        let responseData = await fetch(url, requestOptions);
        responseData = await responseData.json();
        if (responseData.error) {
            setError(responseData.error);
        }
        else {
            setError(null);
            if (responseData && responseData.length) {
                let columns = [];
                for (let key in responseData[0]) {
                    columns.push({
                        id: key,
                        field: key,
                        headerName: key,
                        key: key,
                        label: key
                    });
                }
                if (columns.length) {
                    columns.push({
                        id: "approvedQuantity",
                        field: "approvedQuantity",
                        headerName: "approvedQuantity",
                        key: "approvedQuantity",
                        label: "approvedQuantity"
                    });

                }
                setCsvReport({
                    data: responseData.map((item) => {
                        return {
                            ...item,
                            deliveryExpectedDate: moment.utc(item.deliveryExpectedDate).utcOffset('+05:30').format('DD/MM/YYYY')
                        }
                    })
                    , headers: columns,
                    filename: `fabric_forecast_open_${new Date()}.csv`
                })
            }
            csvLink.current.link.click();
        }
        setBuffer(false);
    }

    return (
        <>
            <MDButton
                variant="outlined"
                color="info"
                size="large"
                circular="True"
                onClick={downloadHandler}
            >
                {buffer ? <CircularProgress /> : "Download Open Forecast"}
            </MDButton>
            <CSVLink {...csvReport} ref={csvLink} target='_blank' />
            {error && <MDAlert color="error">{error}</MDAlert>}
        </>
    )
}

export default ForecastDownload;