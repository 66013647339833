import React from 'react';
import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import vendorDetailsSchema from './vendorUtils'
import AddNewVendor from './AddNewVendor'
import Cookies from 'universal-cookie';
import {
    CircularProgress, TextField, Paper, Alert, Autocomplete, Snackbar
} from '@mui/material';
import MDBox from '../../../../components/MDBox';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    bgcolor: 'background.paper',
    p: 4,
}
const VendorDetails = () => {
    const [rows, setRows] = useState([]);
    const cookies = new Cookies();
    const [buffer, setBuffer] = useState(false);
    const [error, setError] = useState(null);
    const [rowSelectionModel, setRowSelectionModal] = useState([]);
    const [addNewRow, setAddNewRow] = useState([
        { ...vendorDetailsSchema, createdBy: cookies.get("id") }
    ]);
    const [snackBar, setSnackBar] = useState("");

    const columns = [
        {
            id: 'id',
            field: 'id',
            align: 'left',
            disablePadding: false,
            headerName: 'Id',
            key: 'id',
            label: 'Id',
            width: 100,
        },
        {
            id: 'internal_id',
            field: 'internal_id',
            align: 'left',
            disablePadding: false,
            headerName: 'Internal id',
            key: 'internal_id',
            label: 'Internal Id',
            width: 100,
        },
        {
            id: 'name',
            field: 'name',
            align: 'left',
            disablePadding: false,
            headerName: 'Name',
            label: 'name',
            width: 300,
        },
        {
            id: 'gst',
            field: 'gst',
            align: 'left',
            disablePadding: false,
            headerName: 'GST',

            label: 'GST',
            width: 200,
        },
        {
            id: 'phone_number',
            field: 'phone_number',
            align: 'left',
            disablePadding: false,
            headerName: 'Phone number',
            label: 'phone_number',
            width: 150,
        },
        {
            id: 'phone_number_2',
            field: 'phone_number_2',
            align: 'left',
            disablePadding: false,
            headerName: 'Phone Number 2',
            label: 'phone_number_2',
            width: 150,
        },
        {
            id: 'email',
            field: 'email',
            align: 'left',
            disablePadding: false,
            headerName: 'Email',
            label: 'email',
            width: 300,
        },
        {
            id: 'address',
            field: 'address',
            align: 'left',
            disablePadding: false,
            headerName: 'Address',
            label: 'address',
            width: 100,
        },
        {
            id: 'city',
            field: 'city',
            align: 'left',
            disablePadding: false,
            headerName: 'City',
            label: 'city',
            width: 100,
        },
        {
            id: 'pincode',
            field: 'pincode',
            align: 'left',
            disablePadding: false,
            headerName: 'Pincode',
            label: 'pincode',
            width: 150,
        },
        {
            id: 'in_haryana',
            field: 'in_haryana',
            align: 'left',
            disablePadding: false,
            headerName: 'In haryana',
            label: 'in_haryana',
            width: 150,
        },
        {
            id: 'is_active',
            field: 'is_active',
            align: 'left',
            disablePadding: false,
            headerName: 'is_active',
            label: 'is_active',
            width: 200,
        }
    ];

    const fetchVendorView = async () => {
        setBuffer(true);
        let url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/vendorMaster/details/vendorDetails`;
        const requestOptions = {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            redirect: 'follow',
        };
        const res = await fetch(url, requestOptions);
        if (res.ok) {
            let data = await res.json();
            console.log(data, 'fv')
            setRows(data);

        }
        if (!res.ok) {
            console.log(res);
            setError('Error in displaying vendorDetails data');
        }
        setBuffer(false);
    }

    useEffect(() => {
        (async () => {
            await fetchVendorView();
        })()
    }, [])

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', height: '100%' }}>
            <MDBox>
                <Snackbar
                    open={snackBar != 0}
                    autoHideDuration={1500}
                    onClose={() => {
                        setSnackBar(0)
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity={snackBar == 1 ? 'success' : snackBar == 2 ? 'error' : ''} >{snackBar == 1 ? 'New vendors added Successfully' : snackBar == 2 ? 'failed to create  new vendors' : ''}</Alert>
                </Snackbar>
                <AddNewVendor addNewRow={addNewRow} setAddNewRow={setAddNewRow} snackBar={snackBar} setSnackBar={setSnackBar}
                    internalIds={rows?.map((item) => item.internal_id)}
                />
                <div style={{ display: 'flex', flexDirection: 'column', gap: "0.5rem" }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: "0.5rem" }}>
                        <div style={{ height: "56.875rem", width: '100%' }}>
                            {buffer && <div style={{
                                textAlign: 'center',
                                padding: '20vh 0vh',
                                marginLeft: '50%'
                            }}>
                                <CircularProgress />
                            </div>}
                            {!buffer && (error == null) &&
                                <DataGrid
                                    sx={{ height: '100%' }}
                                    rows={rows}
                                    rowHeight={50}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 50 },
                                        },
                                    }}
                                    onRowSelectionModelChange={(newRowSelectionModel) => {
                                        setRowSelectionModal(newRowSelectionModel)
                                    }}
                                    rowSelectionModel={rowSelectionModel}
                                    pageSizeOptions={[50, 75, 100]}

                                />

                            }
                            {!buffer && (error != null) && <Alert severity='error' dismissible='true'>{error}</Alert>}
                        </div>
                    </div>
                </div>
            </MDBox>
        </Paper >
    );
}
export default VendorDetails;