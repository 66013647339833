import React from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import FabricDetails from './fabricDetails/fabricDetailsView/FabricMasterView'
import VerifyLoginIn from "../authentication/login-verification";
import { Box } from '@mui/material';
import { Route, Router, Link, Routes, Outlet } from "react-router-dom";
function Dashboard() {

  return (
    <>
      <VerifyLoginIn />
      <DashboardNavbar />
      <DashboardLayout>
        <Box display="flex">
          <Box sx={{ m: 1, mt: 0 }}><Link to="fabricMaster">FabricMaster</Link></Box>
          <Box sx={{ m: 1, mt: 0 }}><Link to="vendorMaster">VendorMaster</Link></Box>
          <Box sx={{ m: 1, mt: 0 }}><Link to="fabricToFabricMapping">FabricToFabricMapping</Link></Box>
          <Box sx={{ m: 1, mt: 0 }}><Link to="fabricToVendorMapping">FabricToVendorMapping</Link></Box>
        </Box>
        <Outlet />
      </DashboardLayout>
    </>
  );
}

export default Dashboard;
