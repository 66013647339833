//

// Material Dashboard 2 React Base Styles
import colors from "../../../../assets/theme/base/colors";
import typography from "../../../../assets/theme/base/typography";
import borders from "../../../../assets/theme/base/borders";

const { info, inputBorderColor, dark } = colors;
const { size } = typography;
const { borderWidth } = borders;

const input = {
  
};

export default input;
