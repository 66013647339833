const vendorDetailsSchema={
    id:Date.now(),
    internalId:"",
    name:"",
    gst:"",
    phoneNumber:"",
    phoneNumber2:""  ,
    email:""  ,
    address:"",
    city:"",
    pincode:"",       
    inHaryana:""   ,  
    isActive:1    
};
module.exports=vendorDetailsSchema;