import React from "react";

import { useState, useEffect } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

import Cookies from 'universal-cookie';

const VerifyLoginIn = () => {

    const cookies = new Cookies();
    const navigate = useNavigate();

    let accessToken = cookies.get('jwt');
    let refreshToken = cookies.get('refresh');
    if (accessToken == undefined && refreshToken == undefined) {
        navigate('/');
    }
    else if (!accessToken) {

        // Todo: Fetch this from env
        fetch(`${process.env.REACT_APP_SERVER_URL}/v0/fms/api/auth/refreshtoken`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ refreshToken: refreshToken }),
        })
            .then(async (response) => {
                const json = await response.json();
                let jwtExpire = new Date();
                jwtExpire.setTime(jwtExpire.getTime() + (json.allTokens.jwt.expireTime * 1000));
                let refreshExpire = new Date();
                refreshExpire.setTime(refreshExpire.getTime() + (json.allTokens.refresh.expireTime * 1000));
                cookies.set('jwt', json.allTokens.jwt.token, {
                    expires: jwtExpire
                });
                cookies.set('refresh', json.allTokens.refresh.token, {
                    expires: refreshExpire
                });
            })
            .catch((err) => {

            })
    }
    return (
        <></>
    )
}

export default VerifyLoginIn;