import VerifyLoginIn from "../authentication/login-verification";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import PurchaseOrderCreate from "./purchase-order-create";
import PurchaseOrderView from "./purchase-order-view";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import Grn from '../purchase-order/grn-create/index'

function PurchaseOrder() {
    return (
        <>
            <VerifyLoginIn />
            <DashboardNavbar />
            <DashboardLayout>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '3rem' }}>
                    <PurchaseOrderCreate />
                    <Grn />
                </div>
                <div style={{ marginTop: 12 }}>
                    <PurchaseOrderView />
                </div>

            </DashboardLayout >
        </>
    )
}

export default PurchaseOrder;