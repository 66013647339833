import React from "react";
import { useState, useRef, useEffect } from "react";
import Cookies from "universal-cookie";
// Mui imports
import MDButton from "../../../components/MDButton";
import { CSVLink } from 'react-csv';
import MDBox from "../../../components/MDBox";
import MDAlert from "../../../components/MDAlert";
import {
    Modal,
    Stepper,
    Step,
    StepLabel,
    Button,
    CircularProgress
} from "@mui/material";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Check from '@mui/icons-material/Check';
import { styled } from "@mui/material/styles";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import Papa from "papaparse";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { downloadCSV } from "../../utils/utils";


function ForecastApproval() {

    const [openModal, setOpenModal] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [buffer, setBuffer] = useState(false);
    const csvLink = useRef();
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadError, setUploadError] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [data, setData] = useState([]);
    const [validationError, setValidationError] = useState(null);
    const [csvReport, setCsvReport] = useState({ data: [], headers: [], filename: 'forecast_approved.csv' });
    const fileInputRef = useRef(null);

    const cookies = new Cookies();

    const fileSelectedHandler = (e) => {
        if (e.target && e.target.files[0]) {
            let file = e.target.files[0];
            setSelectedFile(file);
            setUploadError(null);
            setUploadSuccess(null);
            setFileName(document.getElementById('file').value);
        } else {
            console.error('error while adding file');
        }
    }

    const fileUploadHandler = async (e) => {
        if (data?.length) {

            // Todo: This will be changed later
            await fetch(`${process.env.REACT_APP_SERVER_URL}/v0/fms/api/forecastApproval/approve`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "access-token": await cookies.get('jwt'),
                    "refresh-token": await cookies.get('refresh')
                },
                body: JSON.stringify({ data: data }),
            }).then(async (res) => {
                if (res.ok) {
                    const responseData = await res.json();
                    setSelectedFile(null);
                    setUploadError(null);
                    setFileName(null);
                    setUploadSuccess("File Uploaded Successfully");
                    setData([])

                    if (responseData && responseData.length) {
                        const columns = Object.keys(responseData[0]);
                        downloadCSV({
                            data: responseData.map((item) => Object.values(item)),
                            headers: columns,
                            filename: `fabric_forecast_open_${new Date()}.csv`
                        })
                    }
                }
                if (!res.ok) {
                    setUploadError("File Upload Failed");
                }
                setOpenModal(false);
            }, function (e) {
                alert("Error submitting form!");
            });
        } else {
            alert('No csv attached');
        }
    }

    const fetchDataHandler = async () => {
        if (selectedFile != null) {
            Papa.parse(selectedFile, {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    setData(results?.data);
                },
            });
        }
    }



    const handleOpen = () => { setOpenModal(true) };
    const handleClose = () => { setOpenModal(false) };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80vw',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const steps = [
        "Submit File"
    ];

    const QontoConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10,
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#784af4',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#784af4',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderTopWidth: 3,
            borderRadius: 1,
        },
    }));

    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#784af4',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#784af4',
            zIndex: 1,
            fontSize: 18,
        },
        '& .QontoStepIcon-circle': {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    }));

    function QontoStepIcon(props) {
        const { active, completed, className } = props;

        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <Check className="QontoStepIcon-completedIcon" />
                ) : (
                    <div className="QontoStepIcon-circle" />
                )}
            </QontoStepIconRoot>
        );
    }

    useEffect(() => {
        fetchDataHandler();
    }, [selectedFile]);
    return (
        <>
            <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <MDButton
                    variant="outlined"
                    color="info"
                    size="large"
                    circular="True"
                    onClick={handleOpen}
                >
                    Approved Forecast Upload
                </MDButton>
            </div>
            <CSVLink {...csvReport} ref={csvLink} target='_blank' />
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <MDBox sx={style}>
                    <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>


                    {
                        !buffer ?
                            (
                                activeStep === 0 && (
                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 40 }}>
                                        <TableContainer style={{ overflow: 'scroll', maxHeight: "40vh" }}>
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center">Fabric Id</TableCell>
                                                        <TableCell align="center">Forecasted Quantity</TableCell>
                                                        <TableCell align="center">Approved Quantity</TableCell>
                                                        <TableCell align="center">Delivery Expected Date</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {data.map((row) => (
                                                        <TableRow key={row.fabricId}>
                                                            <TableCell component="th" scope="row" align="center">
                                                                {row.fabricId}
                                                            </TableCell>
                                                            <TableCell align="center">{row.forecastedQuantity}</TableCell>
                                                            <TableCell align="center">{row.approvedQuantity}</TableCell>
                                                            <TableCell align="center">{row.deliveryExpectedDate}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <form encType="multipart/form-data"
                                            onClick={() => document.querySelector(".input-field").click()}
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                border: "2px dashed #1475cf",
                                                height: "5rem",
                                                width: "100%",
                                                cursor: "pointer",
                                                borderRadius: "0.7rem"

                                            }}>
                                            <input type="file" name="file" className="input-field" id="file" accept=".csv" onChange={fileSelectedHandler} ref={fileInputRef} style={{
                                                marginBottom: "10px"
                                            }} hidden />



                                            <MDButton variant="outlined">upload</MDButton>
                                        </form>
                                        <section className="uploaded-file" style={{
                                            margin: "0.625rem 0",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "0.938rem 1.25rem",
                                            borderRadius: "0.312rem",
                                            backgroundColor: "#e9f0ff"
                                        }}>
                                            <span style={{ display: "flex", alignItems: "center" }}>
                                                {fileName}
                                                <DeleteIcon onClick={() => { setFileName(null); setSelectedFile(null) }}></DeleteIcon>
                                            </span>
                                        </section>
                                        <MDButton size="medium" variant="contained" style={{ marginTop: 20 }} onClick={fileUploadHandler}>Submit</MDButton>
                                        {validationError &&
                                            <MDAlert color="error" dismissible="true" marginTop="1.2rem">{validationError}</MDAlert>
                                        }
                                    </div>)
                            )
                            : (
                                activeStep === 0 &&
                                (<div style={{ marginTop: 20, marginBottom: 20, marginLeft: "50%" }}>
                                    <CircularProgress />
                                </div>)
                            )
                    }
                </MDBox>

            </Modal>
        </>
    );
}

export default ForecastApproval;
