import React from "react";

import { useState, useEffect } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

import Cookies from 'universal-cookie';

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";

// Authentication layout components
import BasicLayout from "../components/BasicLayout";

function Login() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    phoneNumber: '',
    password: '',
  });

  const [loginError, setLoginError] = useState(null);

  const [checked, setChecked] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const cookies = new Cookies();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    const data = {
      phoneNumber: formData.phoneNumber,
      password: formData.password
    }
    setLoginError(null);
    // Todo: Fetch this from env
    await fetch(`${process.env.REACT_APP_SERVER_URL}/v0/fms/api/auth/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    })
      .then(async (response) => {
        if (!response.ok) {
          setLoginError("Invalid Phone Number Or Password");
        }
        if (response.ok) {
          const json = await response.json();
          let jwtExpire = new Date();
          jwtExpire.setTime(jwtExpire.getTime() + (json.allTokens.jwt.expireTime * 1000));
          let refreshExpire = new Date();
          refreshExpire.setTime(refreshExpire.getTime() + (json.allTokens.refresh.expireTime * 1000));
          cookies.set('jwt', json.allTokens.jwt.token, {
            expires: jwtExpire
          });
          cookies.set('refresh', json.allTokens.refresh.token, {
            expires: refreshExpire
          });
          cookies.set('id', json.id);
          cookies.set('name', json.username);
          navigate('/dashboard', { replace: true });
        }
      })
      .catch((err) => {

      })
  }

  const isloggedin = async () => {
    let accessToken = await cookies.get('jwt');
    let refreshToken = await cookies.get('refresh');
    if (accessToken || refreshToken) {
      // Todo: Fetch this from env
      await fetch(`${process.env.REACT_APP_SERVER_URL}/v0/fms/api/auth/refreshtoken`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshToken: refreshToken }),
      })
        .then(async (response) => {
          const json = await response.json();
          let jwtExpire = new Date();
          jwtExpire.setTime(jwtExpire.getTime() + (json.allTokens.jwt.expireTime * 1000));
          let refreshExpire = new Date();
          refreshExpire.setTime(refreshExpire.getTime() + (json.allTokens.refresh.expireTime * 1000));
          cookies.set('jwt', json.allTokens.jwt.token, {
            expires: jwtExpire
          });
          cookies.set('refresh', json.allTokens.refresh.token, {
            expires: refreshExpire
          });
          navigate('/dashboard');
        })
        .catch((err) => {
          
        })
    }
  }

  useEffect(() => {
   isloggedin();
  },[])

  return (
    <>
    <BasicLayout>
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="Phone Number"
                label="Phone Number"
                name="phoneNumber"
                onChange={handleChange}
                placeholder="Enter Phone Number"
                fullWidth
                required
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                name="password"
                onChange={handleChange}
                placeholder="Enter Password"
                fullWidth
                required
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" onClick={handleLoginSubmit} fullWidth>
                sign in
              </MDButton>
            </MDBox>
            {loginError && <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              onClick={handleSetRememberMe}
              sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
            >
              &nbsp;&nbsp;Invalid Phone Number or Password
            </MDTypography>}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
    </>
  );
}

export default Login;
