//

// Material Dashboard 2 React base styles
import colors from "../../../../assets/theme/base/colors";

// Material Dashboard 2 React helper functions
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import boxShadow from "../../../../assets/theme/functions/boxShadow";

const { white } = colors;

const stepIcon = {
  
};

export default stepIcon;
