//

// Material Dashboard 2 React base styles
import colors from "../../../../assets/theme-dark/base/colors";

// Material Dashboard 2 React helper functions
import pxToRem from "../../../../assets/theme-dark/functions/pxToRem";

const { transparent } = colors;

const select = {
  
};

export default select;
