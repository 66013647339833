//

// Material Dashboard 2 React base styles
import colors from "../../../../assets/theme/base/colors";
import borders from "../../../../assets/theme/base/borders";
import typography from "../../../../assets/theme/base/typography";

// Material Dashboard 2 React helper functions
import pxToRem from "../../../../assets/theme/functions/pxToRem";

const { light, text, dark } = colors;
const { borderRadius } = borders;
const { size } = typography;

const menuItem = {
  
};

export default menuItem;
