//

// Material Dashboard 2 React base styles
import colors from "../../../../assets/theme/base/colors";

const { text } = colors;

const formLabel = {
  
};

export default formLabel;
