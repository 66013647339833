import React from 'react'
import { MenuItem, FormControl, InputLabel, Select } from '@mui/material';
const DropDown = ({ selectValue, setSelectValue, label, stores, size }) => {
    return (
        < FormControl fullWidth size={size}>
            <InputLabel>{label}</InputLabel>
            <Select
                value={selectValue}
                label={label}
                onChange={(e) => setSelectValue(e.target.value)}
                size='small'
            >
                {stores.map((option) => (
                    <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
            </Select>
        </FormControl >
    )
}
export default DropDown;