//

// Material Dashboard 2 React Base Styles
import colors from "../../../../assets/theme-dark/base/colors";
import typography from "../../../../assets/theme-dark/base/typography";

const { text, info } = colors;
const { size } = typography;

const inputLabel = {
  
};

export default inputLabel;
