
const fabricVendorMappingSchema=  {
    id:Date.now(),
    vendorMasterId: "",
    fabricMasterId:"",
    rate:"",
    moq:"",
    createdBy:0
};

export default fabricVendorMappingSchema;