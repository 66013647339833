import React from "react";
import VerifyLoginIn from "../authentication/login-verification";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import ForecastUpload from "./forecast-upload/index";
import ForecastApproval from "./forecastApproval";
const Forecast = () => {

    return (
        <>
            <VerifyLoginIn />
            <DashboardNavbar />
            <DashboardLayout>
                <div style={{display:'flex'}}>
                <ForecastUpload />
                <ForecastApproval/>
                </div>
            </DashboardLayout>
        </>
    )
}

export default Forecast;