//

// Material Dashboard 2 React helper functions
import pxToRem from "../../../assets/theme/functions/pxToRem";

// Material Dashboard 2 React base styles
import colors from "../../../assets/theme/base/colors";
import boxShadows from "../../../assets/theme/base/boxShadows";
import borders from "../../../assets/theme/base/borders";

const { transparent } = colors;
const { lg } = boxShadows;
const { borderRadius } = borders;

const popover = {
  
};

export default popover;
