//

// Material Dashboard 2 React Base Styles
import colors from "../../../../assets/theme/base/colors";
import borders from "../../../../assets/theme/base/borders";
import typography from "../../../../assets/theme/base/typography";

// Material Dashboard 2 React helper functions
import pxToRem from "../../../../assets/theme/functions/pxToRem";

const { inputBorderColor, info, grey, transparent } = colors;
const { borderRadius } = borders;
const { size } = typography;

const inputOutlined = {
  
};

export default inputOutlined;
