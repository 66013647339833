import { useState, useEffect } from 'react';
import {
    Modal,
    Button,
    CircularProgress,
    Stepper,
    Step,
    StepLabel,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    TableHead,
    Snackbar,
    Paper,
    Alert,
    Autocomplete,
} from '@mui/material';
import MDButton from '../../../components/MDButton';
import Cookies from 'universal-cookie';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

function ReturnGrnModal({ row ,setReturnFromProdError,setSnackBar,handleClose}) {
    const { purchaseOrderId, purchaseCode, quantity, rate, returnFabricId, recievedQuantity,itemId,productionOutId,currentStockInQuantity } = row;
    console.log(currentStockInQuantity)
    const [grnData, setGrnData] = useState([]);
    const todayDate = new Date();
    const [isValid, setIsValid] = useState(true);
    const [rows, setRows] = useState([{ purchaseOrderId, purchaseCode, quantity, rate, returnFabricId, recievedQuantity, warningMessage: '', grnQty: currentStockInQuantity, location: '', invoiceNumber: '', invoiceDate: todayDate }]);
    const [alertOpen, setAlertOpen] = useState(false);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [locationList, setLocationList] = useState([]);
    const cookies = new Cookies();
    // console.log(rows, isValid,'rows');
    const fetchLocationList = async () => {
        const url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/inventoryLocation/list`;
        const options = {
            'method': 'GET',
        };
        const res = await fetch(url, options);
        let data = await res.json();
        data = data.map((item) => item.location);
        setLocationList(data);
    }

    const handleInputChange = (index, columnName, value) => {
        const updatedRows = [...rows];
        if (columnName === 'rate') {
            if (parseInt(value) < 1) {
                setAlertMessage('Rate should be Positive');
                setAlertOpen(true);
                setIsValid(false);
                return;
            }
        }
        else if (columnName === 'grnQty') {
            if (parseInt(value) < 1) {
                setAlertMessage('Quantity should be greater than 0');
                setAlertOpen(true);
                setIsValid(false);
                return;
            }
            const grnQty = parseFloat(value, 10) || 0;
            const fulfilledQty = parseFloat(updatedRows[index].recievedQuantity, 10) || 0;
            let totalgrnQty = 0;
            for (let ind in updatedRows) {
                if (ind == index)
                    continue;
                totalgrnQty += parseFloat(updatedRows[ind].grnQty);
            }
            const totalQty = grnQty + fulfilledQty + totalgrnQty;
            const qty = parseFloat(updatedRows[index].quantity, 10) || 0;
            if (totalQty > qty + (0 * qty) / 10) {
                updatedRows[index].warningMessage = ` ${totalQty - (qty + (0 * qty / 10))} more than allowed PoQty`;
                setIsValid(false);
            }
            else {
                updatedRows[index].warningMessage = '';
                setIsValid(true);
            }
        }
        updatedRows[index] = { ...updatedRows[index], [columnName]: value };
        setRows([...updatedRows]);
    };

    const handleAddRow = () => {
        const lastRow = rows[rows.length - 1];
        const extractedRow = {
            rate: lastRow.rate,
            grnQty: lastRow.grnQty,
            invoiceNumber: lastRow.invoiceNumber,
            location: lastRow.location,
            invoiceDate: lastRow.invoiceDate,
        };
        const isLastRowValid = Object.values(extractedRow).every(value => value !== '');
        if (!isLastRowValid) {
            setAlertMessage('Please fill in all column in the last row before adding a new one.');
            setAlertOpen(true);
        }
        else
            setRows([...rows, { purchaseOrderId, purchaseCode, quantity, rate, returnFabricId, recievedQuantity, warningMessage: '', grnQty: '', location: '', invoiceNumber: '', invoiceDate: todayDate }]);
    };

    const handleRemoveRow = (index) => {
        const updatedRows = [...rows];
        if (updatedRows.length > 1) {
            updatedRows.splice(index, 1);
            setIsValid(true);
        }
        setRows(updatedRows);
    };

    const doReturnGrn = async (grnData) => {
       const productionInData={
            itemId:itemId, productionOutId:productionOutId
        }
        const grnUrl = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/grn/new/returnRolls`;
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(
                {
                    grnData,
                    productionInData,
                    raisedBy: cookies.get("id")
                }
            )
        };
        console.log(grnData)
        const res = await fetch(grnUrl, requestOptions);
        
        if (res.ok) {
            const data = await res.json();
            setReturnFromProdError(1);
            setSnackBar(`${grnData?.returnFabricId} inventorize as new GRN successfully`);
            setTimeout(handleClose,2000);
        }
        else if (!res.ok) {
            const data = await res.json();
            setReturnFromProdError(2);
            setSnackBar(data);
            // setTimeout(handleClose,2000);
        }
        // setBuffer(false);
    }

    const handleOnSubmit = async () => {
        const rowsData = [];
        for (let rowOb of rows) {
            const { purchaseOrderId, purchaseCode, quantity, rate, returnFabricId, recievedQuantity,
                grnQty, location, invoiceNumber, invoiceDate } = rowOb;
                const extractedRow = {
                    purchaseOrderId,
                    purchaseCode,
                    quantity,
                    rate,
                    returnFabricId,
                    recievedQuantity,
                    grnQty:grnQty-currentStockInQuantity,
                    location,
                    invoiceNumber,
                    invoiceDate
                };
            let isRowValid = Object.values({quantity,
                returnFabricId,
                grnQty,
                location,
                invoiceDate}).every(value => value !== '');
            if(extractedRow?.grnQty<=0)
                {
                    setAlertMessage(`grnQty should be more than "${currentStockInQuantity}".`);
                    setAlertOpen(true);
                    return;
                }
            rowsData.push(extractedRow)
            if (!isRowValid) {
                setAlertMessage('Please fill in all input field in the last row before adding a new one.');
                setAlertOpen(true);
                return;
            }
        }
        if ((!alertOpen) && isValid) {
            await doReturnGrn(rowsData)

        }
    }
    useEffect(() => { fetchLocationList() }, []);
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{ display: 'flex', width: "100%", height: '25rem', overflow: 'scroll' }}>
                {/* <GrnPdfGenerator data={grnData} vendor={selectedVendorName} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} activeStep={activeStep} setCloseGrnModal={setOpen} /> */}
                <Snackbar
                    open={alertOpen}
                    autoHideDuration={1000}
                    onClose={() => setAlertOpen(false)}
                    message={alertMessage}

                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                />
                <TableContainer style={{ maxHeight: '24rem' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'>PoCode</TableCell>
                                <TableCell align='center'>FabricID</TableCell>
                                <TableCell align='center'>Rate</TableCell>
                                <TableCell align='center'>PoQty</TableCell>
                                {/* <TableCell align='center'>fulfilledQty</TableCell> */}
                                <TableCell align='center'>grnQty</TableCell>
                                <TableCell align='center'>Location</TableCell>
                                <TableCell align='center'>invoiceNumber</TableCell>
                                <TableCell align='center'>InvoiceDate</TableCell>
                                <TableCell align='center'>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell align='center'>Fablestreet</TableCell>
                                    <TableCell align='center'>{row.returnFabricId}</TableCell>
                                    <TableCell align='center' sx={{ minWidth: '7rem' }}>
                                        <TextField
                                            variant="outlined"
                                            value={row.rate}
                                            onChange={(e) => handleInputChange(index, 'rate', e.target.value)}
                                            size="small"
                                            type="number"
                                            disabled="true"
                                        />
                                    </TableCell>
                                    <TableCell align='center'>{row.quantity}</TableCell>
                                    {/* <TableCell align='center'>{row.recievedQuantity}</TableCell> */}
                                    <TableCell align='center'>
                                        <TextField
                                            variant="outlined"
                                            value={row.grnQty}
                                            onChange={(e) => handleInputChange(index, 'grnQty', e.target.value)}
                                            style={{ borderColor: isValid ? '' : 'red', width: '7rem' }}
                                            size="small"
                                            type="number"
                                        />
                                        {row.warningMessage && (
                                            <div style={{ color: 'red', fontSize: '12px', marginTop: '0.25rem' }}>
                                                {row.warningMessage}
                                            </div>
                                        )}
                                    </TableCell>
                                    <TableCell align='center' sx={{ minWidth: '12.5rem' }}>

                                        <Autocomplete
                                            options={locationList}
                                            value={row.location}
                                            onChange={(e, newValue) => handleInputChange(index, 'location', newValue)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            )}

                                        />
                                    </TableCell>
                                    <TableCell align='center'>
                                        <TextField
                                            variant="outlined"
                                            value={row.invoiceNumber}
                                            onChange={(e) => handleInputChange(index, 'invoiceNumber', e.target.value)}
                                            size="small"
                                            disabled="true"
                                        />
                                    </TableCell>

                                    <TableCell align='center'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Date"
                                                value={row.invoiceDate ? dayjs(row.invoiceDate) : dayjs()}
                                                format="DD/MM/YYYY"
                                                sx={{ minWidth: '10rem' }}
                                                onChange={(newValue) => {
                                                    const formattedDate = newValue.toISOString();
                                                    handleInputChange(index, 'invoiceDate', formattedDate);
                                                }}

                                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                            />
                                        </LocalizationProvider>
                                    </TableCell>

                                    <TableCell align='center'>
                                        <Button
                                            variant="contained" color="success"
                                            size="medium"
                                            sx={{ color: '#0C0705' }}
                                            onClick={() => handleRemoveRow(index)}
                                        >
                                            <DeleteIcon />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {/* <MDButton variant='outlined' sx={{ marginTop: '0.4rem' }} circular size="medium" disabled={!isValid} onClick={handleAddRow}>
                        Add
                    </MDButton> */}


                </TableContainer>

            </div>
            <div style={{ display: 'flex', margin: '.5rem', marginTop: '0.2rem', justifyContent: 'space-between' }} >
                {/* 
                <MDButton variant="outlined" size="medium" onClick={() => setActiveStep(1)} >
                    Close
                </MDButton> */}
                <MDButton variant="contained" size="medium" onClick={handleOnSubmit}  disabled={!isValid} >
                    Submit
                </MDButton>

            </div>
        </Paper>
    )
}

export default ReturnGrnModal;