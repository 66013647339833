//

// Material Dashboard 2 React base styles
import colors from "../../../../assets/theme/base/colors";
import typography from "../../../../assets/theme/base/typography";

// Material Dashboard 2 React helper functions
import pxToRem from "../../../../assets/theme/functions/pxToRem";

const { dark } = colors;
const { size, fontWeightBold } = typography;

const formControlLabel = {
  
};

export default formControlLabel;
