import React from 'react';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Cookies from 'universal-cookie';
import fabricDetailsSchema from './fabricUtils'

import {
  TextField,
  Box,
  Modal,
  Snackbar
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MDButton from '../../../../components/MDButton';

let cookies = new Cookies();

const handleInputChange = (e, addNewRow, setAddNewRow, columnName, params, fabricIds = null, setError = null) => {
  const newRows = addNewRow?.map(
    (item) => {
      return item.id === params?.row?.id ? { ...item, [columnName]: e.target.value } : item
    }
  )
  if (setError !== null && fabricIds !== null && columnName == 'fabricId')
    validate(fabricIds, newRows, setError);
  setAddNewRow(newRows)
}

const addRow = (setAddNewRow) => {
  setAddNewRow(preRows => [...preRows, { ...fabricDetailsSchema, id: Date.now(), createdBy: cookies.get("id") }])
}
const removeCurrentRow = (addNewRow, setAddNewRow, params, fabricIds, setError) => {
  const newRows = addNewRow?.filter(
    (item) => {
      return item.id !== params?.row?.id ? true : false
    }
  )
  validate(fabricIds, newRows, setError);
  setAddNewRow(newRows);
}

const validate = (fabricIds, newRows, setError) => {
  const fabricIdCol = [...fabricIds];
  newRows.forEach(element => {
    fabricIdCol.push(element.fabricId)
  })
  const fabricUniqueSet = new Set(fabricIdCol);
  setError(fabricUniqueSet.size !== fabricIdCol?.length ? 'Duplicate FabricIds' : '');
}

const AddNewFabric = ({ addNewRow, setAddNewRow, fabricIds, snackBar, setSnackBar }) => {
  const [error, setError] = useState('');
  const [snackBarError, setSnackBarError] = useState(false);
  const addNewFabricToFabricList = async (addNewRow) => {

    let url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/fabricMaster/new`;
    const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      redirect: 'follow',
      body: JSON.stringify({
        data: addNewRow
      })
    };
    const res = await fetch(url, requestOptions);
    handleAddNewFabricSubmit();
    if (res.ok) {
      let data = await res.json();
      setSnackBar(1)

    }
    if (!res.ok) {
      setSnackBarError('Error in adding new fabricIds');
      setSnackBar(2)
    }

  }

  const addNewFabrics = () => {
    addNewRow?.forEach((elObj) => {
      if (elObj.fabricId == "") {
        setSnackBarError("fabricId cannot be empty")
        return;
      }
      else if ((!elObj.fabricId.startsWith("FAB"))) {
        setSnackBarError('fabricId starts with "FAB"')
        return;
      }
    });
    //  fms/api/fabricMaster/new
    // call the api
    addNewFabricToFabricList(addNewRow)
  }
  const newFabricColumn = [
    {
      id: 'id',
      field: 'id',
      align: 'left',
      disablePadding: false,
      headerName: 'Id',
      key: 'id',
      label: 'Id',
      width: 150,
    },
    {
      field: 'fabricId',
      headerName: 'Fabric Id',
      width: 150,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            variant="outlined"
            size="small"
            value={params.row.fabricId}
            label='FAB3456'
            onChange={(e) => { handleInputChange(e, addNewRow, setAddNewRow, 'fabricId', params, fabricIds, setError) }}
          />
          {error?.length !== 0 && (
            <div style={{ color: 'red', fontSize: '12px', marginTop: '0.25rem' }}>
              {error}
            </div>
          )}
        </Box>
      ),
    },
    {
      field: 'fabricNameExternal',
      headerName: 'FabricNameExternal',
      width: 150,
      renderCell: (params) => (

        <TextField
          variant="outlined"
          size="small"
          value={params.row.name}
          onChange={(e) => handleInputChange(e, addNewRow, setAddNewRow, 'fabricNameExternal', params)}
        />


      ),
    },
    {
      field: 'fabricNameInternal',
      headerName: 'FabricNameInternal',
      width: 150,
      renderCell: (params) => (
        <TextField
          variant="outlined"
          size="small"
          value={params.row.content}
          onChange={(e) => handleInputChange(e, addNewRow, setAddNewRow, 'fabricNameInternal', params)}
        />
      ),
    },
    {
      field: 'image',
      headerName: 'Image',
      width: 150,
      renderCell: (params) => (
        <TextField
          variant="outlined"
          size="small"
          value={params.row.image}
          onChange={(e) => handleInputChange(e, addNewRow, setAddNewRow, 'image', params)}
        />
      ),
    },
    {
      field: 'color',
      headerName: 'Color',
      width: 150,
      renderCell: (params) => (
        <TextField
          variant="outlined"
          size="small"
          value={params.row.color}
          onChange={(e) => handleInputChange(e, addNewRow, setAddNewRow, 'color', params)}
        />
      ),
    },

    {
      field: 'delete',
      headerName: 'remove',
      width: 150,
      renderCell: (params) => (
        <DeleteIcon onClick={() => { removeCurrentRow(addNewRow, setAddNewRow, params, fabricIds, setError) }} />
      ),
    },


  ];

  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => {
    setOpenModal(false);
  }
  const handleAddNewFabricSubmit = () => {
    setOpenModal(false);
    setAddNewRow([{ ...fabricDetailsSchema, createdBy: cookies.get("id") }]);
    setError('');
    setSnackBarError(false);
  }

  return (
    <Box>
      <MDButton
        variant='outlined'
        size='small'
        style={{ textTransform: 'capitalize' }}
        circular
        onClick={() => setOpenModal(true)}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              fontSize: '1.5rem',
              margin: '0.1rem',
              marginRight: '.6rem'
            }}
          >
            +
          </div>
          <div
            style={{ fontWeight: 'bold', margin: '0.1rem', fontSize: '1rem' }}
          >
            Add New Fabric
          </div>
        </div>
      </MDButton>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            bgcolor: 'background.paper',
            border: '1px solid #000',
            boxShadow: 24,
            p: 4,


          }}
        >

          <Snackbar
            open={snackBarError?.length}
            autoHideDuration={1500}
            onClose={() => {
              setSnackBarError(false)
            }}
            message={snackBarError}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          />
          <DataGrid
            rows={addNewRow}
            columns={newFabricColumn}
            rowHeight={80}
            hideFooter
            disableColumnMenu
            disableSelectionOnClick
            sx={{ maxHeight: '60vh' }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <MDButton variant='outlined' sx={{ marginTop: '0.4rem' }} circular size="medium"
              disabled={error?.length}
              onClick={() => addRow(setAddNewRow)}
            >
              Add
            </MDButton>
            <MDButton variant='outlined' sx={{ marginTop: '0.4rem' }} circular size="medium"
              disabled={error?.length}
              onClick={() => addNewFabrics()}
            >
              Submit
            </MDButton>
          </Box>

        </Box>

      </Modal >
    </Box>
  );
}

export default AddNewFabric;