
const fabricDetailsSchema=  {
    id:Date.now(),
    fabricId: "",
    image: "image",
    color: "",
    fabricNameInternal: "",
    fabricNameExternal: "",
    status: 1,
    current_inventory:"",
    shrinkage: 1,
    storageUom: "",
    buyingUom: "",
    remarks: "",
    minJitReq :"",
    po_avg_rate:"",
    grn_avg_rate:"",
    moq:"",
    max_replenishment:"",
    min_replenishment:"",
    createdBy:0
};

export default fabricDetailsSchema;