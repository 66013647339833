//

// Material Dashboard 2 React base styles
import borders from "../../../../assets/theme/base/borders";
import colors from "../../../../assets/theme/base/colors";

const { white } = colors;
const { borderWidth } = borders;

const stepConnector = {
  
};

export default stepConnector;
