//

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "./layouts/dashboard/index";
import ProductionDashboard from './layouts/productionDashboard/index'
import SignIn from "./layouts/authentication/sign-in/index";
import PurchaseOrder from "./layouts/purchase-order/index";
import Inventory from "./layouts/inventory/index";
import Forecast from "./layouts/forecast/index";
import FabricDetails from './layouts/dashboard/fabricDetails/fabricDetailsView/FabricMasterView'
import VendorDetails from './layouts/dashboard/vendorDetails/vendorDetailsView/vendorDetailsView'
import FabricToFabricMapping from './layouts/dashboard/fabricDetails/fabricToFabricMappingView/FabricToFabricMapping'
import FabricToVendorMapping from './layouts/dashboard/vendorDetails/fabricToVendorMapping/FabricToVendorMapping'
import ProductionOutView from "./layouts/productionDashboard/productionOut/productionOutView/ProductionOutView";
import AddToProduction from "./layouts/productionDashboard/productionOut/productionOutView/AddToProduction";
const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    component: <Dashboard />,
    children: [
      {
        type: "collapse",
        name: "Fabric Master",
        key: "fabricMaster",
        route: "fabricMaster",
        component: <FabricDetails />,
      },
      {
        type: "collapse",
        name: "vendorMaster",
        key: "vendorMaster",
        route: "vendorMaster",
        component: <VendorDetails />,
      },
      {
        type: "collapse",
        name: "FabricToFabricMapping",
        key: "FabricToFabricMapping",
        route: "fabricToFabricMapping",
        component: <FabricToFabricMapping />,
      },
      {
        type: "collapse",
        name: "FabricToVendorMapping",
        key: "FabricToVendorMapping",
        route: "fabricToVendorMapping",
        component: <FabricToVendorMapping />,
      },

    ]
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Forecast",
    key: "upload",
    route: "/upload",
    component: <Forecast />,
  },
  {
    type: "collapse",
    name: "Purchase Order",
    key: "purchase-order",
    route: "/purchase-order",
    component: <PurchaseOrder />,
  },
  {
    type: "collapse",
    name: "ProductionDashBoard",
    key: "production-dashboard",
    route: "/production-dashboard",
    component: <ProductionDashboard />,
    children: [
      {
        type: "collapse",
        name: "productionOut",
        key: "productionOut",
        route: "productionOut",
        component: <ProductionOutView />,
      },
      {
        type: "collapse",
        name: "addToProduction",
        key: "addToProduction",
        route: "addToProduction",
        component: <AddToProduction />,
      },
      {
        type: "collapse",
        name: "FabricToFabricMapping",
        key: "FabricToFabricMapping",
        route: "fabricToFabricMapping",
        component: <FabricToFabricMapping />,
      },
      {
        type: "collapse",
        name: "FabricToVendorMapping",
        key: "FabricToVendorMapping",
        route: "fabricToVendorMapping",
        component: <FabricToVendorMapping />,
      },

    ]
  },
  {
    type: "collapse",
    name: "Inventory",
    key: "Inventory",
    route: "Inventory",
    component: <Inventory />,
  },
];

const sidenavRouteButtons = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    component: <Dashboard />,

  },
  {
    type: "collapse",
    name: "ProductionDashBoard",
    key: "production-dashboard",
    route: "/production-dashboard",
    component: <ProductionDashboard />,
  },
  {
    type: "collapse",
    name: "Forecast",
    key: "upload",
    route: "/upload",
    component: <Forecast />,
  },
  {
    type: "collapse",
    name: "Purchase Order",
    key: "purchase-order",
    route: "/purchase-order",
    component: <PurchaseOrder />,
  },
  {
    type: "collapse",
    name: "Inventory",
    key: "Inventory",
    route: "Inventory",
    component: <Inventory />,
  }
];

export { sidenavRouteButtons, routes };
