import React, { useState } from 'react';
import {
    Modal,
    Stepper,
    Step,
    StepLabel,
    LinearProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TextField,
    Snackbar,
    Grid,
    Chip,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Alert
}
    from '@mui/material'
const InventoryDialog = ({ items, open, onClose ,orderTypePoObjRowKey,orderTypePoObj,setOrderTypePoObj}) => {

    const [updatedItems, setUpdatedItems] = useState([...items]);
    // console.log(updatedItems, 'items')
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const handleTextFieldChange = (index) => (e) => {
        let { value } = e.target;
        // Check if value is a number and non-zero
        if (!isNaN(value) && parseFloat(value) !== 0 && parseInt(value) <= parseInt(updatedItems[index].quantity)) {

            setUpdatedItems((prevItems) =>
                prevItems.map((item, i) => (i === index ? { ...item, outwardQuantity: value } : item))
            );
        }
        else {
            if (parseInt(value) > parseInt(updatedItems[index].quantity)) {
                setSnackbarMessage(`Quantity cannot exceed ${updatedItems[index].quantity}`);
                setSnackbarOpen(true);
                setTimeout(() => {
                    setSnackbarOpen(false);
                }, 2000);
            }

            setUpdatedItems((prevItems) =>
                prevItems.map((item, i) => (i === index ? { ...item, outwardQuantity: "" } : item))
            );
        }
    };

    const handleSave = () => {
        // Handle save logic here, e.g., update backend or state
        let editedRow = orderTypePoObj[orderTypePoObjRowKey];
        editedRow.fabricWiseData=[...updatedItems];
        let poQty=updatedItems.reduce((acc,el)=>el?.outwardQuantity!==undefined?parseInt(el?.outwardQuantity)+acc:acc,0);
        // console.log( editedRow?.quantity,poQty,'poQty')
        editedRow.quantity=poQty;
        editedRow.amount=poQty*(editedRow.purchaseRate)
        // editedRow.amount
        orderTypePoObj[orderTypePoObjRowKey]=editedRow;
        setOrderTypePoObj({...orderTypePoObj})
     
        onClose();
    };
    const handleCancel=() => {
        // Handle save logic here, e.g., update backend or state
        setUpdatedItems((prevItems) =>
            prevItems.map((item, i) => ( { ...item, outwardQuantity: "" }))
        );
       onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} sx={{ top: '20%', left: '0%', height: '70vh' }}>
           <Snackbar
  open={snackbarOpen}
  message={snackbarMessage}
  autoHideDuration={2000}
  onClose={() => setSnackbarOpen(false)}
  anchorOrigin={{vertical:"top",horizontal:"bottom"}}
>
<Alert severity="warning">{snackbarMessage}</Alert>
</Snackbar>
            
            <Box boxShadow={3}> <DialogTitle>Select fabric {items?.[0].baseFabId} from locations to sent for {items?.[0]?.conversionMethod}</DialogTitle>
            </Box>
            <DialogContent sx={{ overflowY: 'auto' }}>
                {updatedItems.map((item, index) => (
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Chip size='medium' label={`Location:${item.location} `} />
                            <Chip size='medium' label={`Inventory: ${item.quantity}`} />
                        </Box>
                        <TextField
                            key={index}
                            label={item.location}
                            placeholder={item.location}
                            value={item.outwardQuantity}
                            onChange={handleTextFieldChange(index)}
                            fullWidth
                            margin="normal"
                            type="number"
                            inputProps={{ min: 0 }}
                        />
                    </Box>

                ))}
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
                <Button onClick={handleCancel} color="primary">
                    Cancel
                </Button>
            </DialogContent>
        </Dialog>
    );
}


export default InventoryDialog;