export const downloadCSV = (csvUploadReport) => {
    console.log(csvUploadReport.data)
    const csvContent = `${csvUploadReport.headers.join(',')}\n${csvUploadReport.data
        .map(row => row.join(','))
        .join('\n')}`;

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = csvUploadReport.filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


