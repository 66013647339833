import VerifyLoginIn from "../authentication/login-verification";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import InventoryView from "./inventoryView/InventoryView";

const Inventory = () => {
    return (
        <>
            <VerifyLoginIn />
            <DashboardNavbar />
            <DashboardLayout>
                <div>
                    <InventoryView />
                </div>

            </DashboardLayout >
        </>
    )
}

export default Inventory;