
const fabricMappingSchema=  {
    id:Date.now(),
    fabricId: "",
    baseFabricId:"",
    conversionMethod:"",
    moq:"",
    createdBy:0
};

export default fabricMappingSchema;