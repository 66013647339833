import React from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import VerifyLoginIn from "../authentication/login-verification";
import { Box } from '@mui/material';
import { Route, Router, Link, Routes, Outlet } from "react-router-dom";
function ProductionDashboard() {

  return (
    <>
      <VerifyLoginIn />
      <DashboardNavbar />
      <DashboardLayout>
        <Box display="flex">
          <Box sx={{ m: 1, mt: 0 }}><Link to="productionOut">Production_View</Link></Box>
          <Box sx={{ m: 1, mt: 0 }}><Link to="addToProduction">Send_To_Production</Link></Box>
        </Box>
        <Outlet />
      </DashboardLayout>
    </>
  );
}

export default ProductionDashboard;
