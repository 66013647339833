import React, { useRef, useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {
    Modal,
    Box,
} from '@mui/material';
import MDButton from "../../components/MDButton";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    height: '80%',
    overflow: 'auto',
    boxShadow: 24,
    p: 4,
};

const cellStyle = {
    border: '1px solid #000000',
    padding: '.3rem'
};
const currentYear = new Date().getFullYear().toString().slice(-2);
const nextYear = (new Date().getFullYear() + 1).toString().slice(-2);
const newDate = new Date();

const PDFGenerator = ({ data, vendor, isModalOpen, setIsModalOpen, setCloseGrnModal }) => {
    const ref = useRef();
    const keys = data.length ? Object.keys(data[0]).filter(key => key !== 'purchaseOrderId') : [];
    if (keys) {
        keys.unshift("vendorName")
    }
    const generatePDF = () => {
        const input = ref.current;

        html2canvas(input, { scale: 3.7 })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/jpeg');
                const pdf = new jsPDF('p', 'mm', [1200, 1278]);
                pdf.addImage(imgData, 'PNG', 0, 0);
                pdf.save(`GRN_PO_${currentYear}_${nextYear}_${data[0].purchaseCode}_${newDate.getTime()}_.pdf`);
            }).then(() => {
                setCloseGrnModal(false);
                setIsModalOpen(false);
                window.location.reload();
            }).catch((err) => {
            });
    }
    const closeModal = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        if (isModalOpen) {
            setTimeout(generatePDF);

        }
    }, [isModalOpen]);

    return (
        <>
            <Modal
                open={isModalOpen}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: -1, opacity: 0, pointerEvents: 'none' }}
            >
                <>
                    <Box sx={style}>
                        <div ref={ref} style={{ width: '1200px', height: '1278px' }}>

                            <h1 style={{ textAlign: 'center' }}>GOODS RECIEVED NET(GRN)</h1>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {keys?.map((key) => (
                                                <TableCell key={key} style={cellStyle}>{key}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((row, index) => (
                                            <TableRow key={index}>
                                                {keys?.map((key) => (
                                                    <TableCell key={key} style={cellStyle}>{key === 'purchaseCode' ? `FS_PO_${currentYear}_${nextYear}_${row[key]}` : key == 'vendorName' ? vendor : row[key]}</TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Box>
                </>
            </Modal>
        </>
    );
}

export default PDFGenerator;
