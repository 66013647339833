import React from 'react';
import { useState } from 'react';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell
} from '@mui/x-data-grid';
import Cookies from 'universal-cookie';
import fabricVendorMappingSchema from './fabricVendorMappingUtil'
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Modal,
  Snackbar,
  Button,
  TextField,
  Autocomplete
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import MDButton from '../../../../components/MDButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
let cookies = new Cookies();

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));


const StyledBox = styled('div')(({ theme }) => ({
  height: 400,
  width: '100%',
  '& .MuiDataGrid-cell--editable': {
    backgroundColor: theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
    color: theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f',
  },
}));


const addRow = (setAddNewRow, id = null) => {
  setAddNewRow(preRows => [...preRows, { ...fabricVendorMappingSchema, id: id, createdBy: cookies.get("id") }])
}

const validate = (internalIds, newRows) => {
  const internalIdsCol = [...internalIds];
  newRows.forEach(element => {
    if (element?.internalId != "" && element?.internalId !== null)
      internalIdsCol.push(element.internalId)
  })
  const vendorUniqueSet = new Set(internalIdsCol);
  return vendorUniqueSet.size !== internalIdsCol?.length ? true : false;
}
const AddFabricToVendorMapping = ({ addNewRow, setAddNewRow, setSnackBar, rows, refreshFabricToVendorMappingView, setRefreshFabricToVendorMappingView }) => {
  const [error, setError] = useState('');
  const [isDuplicate, setIsDuplicate] = useState(0);
  const [snackBarError, setSnackBarError] = useState(false);
  const addFabricMapping = async (addNewRow) => {
    let url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/fabricMaster/newFabricToFabricMapping`;
    const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      redirect: 'follow',
      body: JSON.stringify({
        data: addNewRow
      })
    };
    const res = await fetch(url, requestOptions);
    handleAddNewFabricSubmit();
    if (res.ok) {
      let data = await res.json();
      setSnackBar(1);
      setAddNewRow([{ ...fabricVendorMappingSchema, createdBy: cookies.get("id") }]);
    }
    if (!res.ok) {
      setSnackBarError('Error in adding new vendors');
      setSnackBar(2)
    }
  }

  const addNewVendor = async () => {
    let escapeApiCall = 0;
    let fabricIdSet = new Set(rows?.map((item) => { return `${item.fabric_id}_${item.base_fab_id}` }));
    addNewRow?.forEach((elObj) => {
      if (elObj?.fabricId == "" || elObj?.baseFabricId == "") {
        setSnackBarError("Please save before submitting")
        escapeApiCall = 1;
        return;
      }
      else if ((!elObj?.fabricId.startsWith("FAB") || !elObj?.baseFabricId.startsWith("FAB"))) {
        setSnackBarError('fabricId/baseFabricId starts with "FAB"')
        escapeApiCall = 1;
        return;
      }
      else if (elObj?.conversionMethod == "") {
        setSnackBarError('add conversion method ex- dye/print ,base');
        escapeApiCall = 1;
        return;
      }
      else if (elObj?.conversionMethod == 'base' && elObj?.fabricId !== elObj?.baseFabricId) {
        setSnackBarError('base fabrics have same fabricId');
        escapeApiCall = 1;
        return;
      }
      else if (fabricIdSet.has(`${elObj?.fabricId}_${elObj?.baseFabricId}`)) {
        setSnackBarError(`${elObj?.fabricId} and ${elObj?.baseFabricId} already present`);
        escapeApiCall = 1;
        return;
      }
    });
    // call the api
    // if (!escapeApiCall)
    //   await addFabricMapping(addNewRow)
  }

  let promiseTimeout;
  // function validateName(username) {
  //   validate(internalIds, [{ internalId: username }, ...addNewRow]);
  //   const isUnique = validate(internalIds, [{ internalId: username }, ...addNewRow]);
  //   setIsDuplicate(isUnique);
  //   return new Promise((resolve) => {
  //     promiseTimeout = setTimeout(
  //       () => {
  //         resolve(isUnique ?
  //           'duplicate vendor id' : '');
  //       },
  //       Math.random() * 100 + 100,
  //     );
  //   });
  // }

  function NameEditInputCell(props) {
    const { error } = props;

    return (
      <StyledTooltip open={!!error} title={error}>
        <GridEditInputCell {...props} />
      </StyledTooltip>
    );
  }

  const handleColumnChange = (id, column, value) => {
    setAddNewRow((prevRows) =>
      prevRows.map((row) =>
        row.id === id ? { ...row, [column]: value } : row
      )
    );
  };
  //VALIDATION PENDING
  const newVendorColumn = [
    {
      id: 'fabric_id',
      field: 'fabric_id',
      align: 'left',
      disablePadding: false,
      // editable: true,
      headerName: 'Fabric Id',
      key: 'fabric_id',
      label: 'Fabric Id',
      width: 300,
      renderCell: (params) => (
        <Autocomplete
          options={rows?.map((item) => item.fabric_id)}
          value={params.row.fabric_id || ''}
          onChange={(event, newValue) =>
            handleColumnChange(params.row.id, 'fabric_id', newValue)
          }
          renderInput={(params) => <TextField {...params} />}
        />
      ),
    },
    {
      id: 'internal_id',
      field: 'internal_id',
      align: 'left',
      disablePadding: false,
      editable: true,
      headerName: 'internal_id',
      key: 'internal_id',
      label: 'Internal',
      width: 150,
      type: 'singleSelect',
      valueOptions: rows?.map((item) => item.internal_id)
    },
    {
      id: 'rate',
      field: 'rate',
      align: 'left',
      disablePadding: false,
      editable: true,
      headerName: 'rate',
      label: 'rate',
      width: 200,
    },
    {
      field: 'moq',
      type: 'moq',
      headerName: 'Moq',
      editable: true,
      width: 100,
    },
  ];


  const [openModal, setOpenModal] = useState(false);
  const [rowModesModel, setRowModesModel] = useState({});
  const handleClose = () => {
    setOpenModal(false);
  }

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setAddNewRow(addNewRow.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = addNewRow.find((row) => row.id === id);
    if (editedRow.isNew) {
      const newRows = addNewRow.filter((row) => row.id !== id);
      setAddNewRow(newRows);
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    const newRows = addNewRow.map((row) => (row.id === newRow.id ? updatedRow : row))
    // validate(internalIds, newRows, setIsDuplicate);
    setAddNewRow(newRows);
    return updatedRow;
  };

  function EditToolbar(props) {
    const { setAddNewRow, addNewRow, setRowModesModel } = props;
    const handleClick = () => {
      const id = Date.now();
      addRow(setAddNewRow, id);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'fabricId' },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" disabled={isDuplicate} startIcon={<AddIcon />} onClick={handleClick}>
          Add
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleAddNewFabricSubmit = () => {
    setOpenModal(false);
    setAddNewRow([{ ...fabricVendorMappingSchema, createdBy: cookies.get("id") }]);
    setError('');
    setSnackBarError(false);
    setRefreshFabricToVendorMappingView(!refreshFabricToVendorMappingView);
  }
  return (
    <Box>
      <MDButton
        variant='outlined'
        size='small'
        style={{ textTransform: 'capitalize' }}
        circular
        onClick={() => setOpenModal(true)}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              fontSize: '1.5rem',
              margin: '0.1rem',
              marginRight: '.6rem'
            }}
          >
            +
          </div>
          <div
            style={{ fontWeight: 'bold', margin: '0.1rem', fontSize: '1rem' }}
          >
            Add New Fabric Vendor Mapping
          </div>
        </div>
      </MDButton>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            bgcolor: 'background.paper',
            border: '1px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Snackbar
            open={snackBarError?.length}
            autoHideDuration={1500}
            onClose={() => {
              setSnackBarError(false)
            }}
            message={snackBarError}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          />
          <Box
            sx={{
              height: 500,
              width: '100%',
              '& .actions': {
                color: 'text.secondary',
              },
              '& .textPrimary': {
                color: 'text.primary',
              },
            }}
          >
            <StyledBox>
              <DataGrid
                rows={addNewRow}
                columns={newVendorColumn}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: { setAddNewRow, addNewRow, setRowModesModel },
                }}
              />
            </StyledBox>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <MDButton variant='outlined' sx={{ marginTop: '0.4rem' }} circular size="medium"
              disabled={error?.length || isDuplicate}
              onClick={() => addNewVendor()}
            >
              Submit
            </MDButton>
          </Box>
        </Box>
      </Modal >
    </Box>
  );
}

export default AddFabricToVendorMapping;