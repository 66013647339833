//

// Material Dashboard 2 React base styles
import boxShadows from "../../../../assets/theme/base/boxShadows";
import typography from "../../../../assets/theme/base/typography";
import colors from "../../../../assets/theme/base/colors";
import borders from "../../../../assets/theme/base/borders";

// Material Dashboard 2 React helper functions
import pxToRem from "../../../../assets/theme/functions/pxToRem";

const { lg } = boxShadows;
const { size } = typography;
const { text, white } = colors;
const { borderRadius } = borders;

const menu = {
  
};

export default menu;
