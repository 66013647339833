import React from 'react';
import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import fabricVendorMappingSchema from './fabricVendorMappingUtil'
import AddNewFabricToVendorMapping from './AddNewFabricToVendorMapping'
import Cookies from 'universal-cookie';
import {
    CircularProgress, Paper, Alert, Snackbar
} from '@mui/material';
import MDBox from '../../../../components/MDBox';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    bgcolor: 'background.paper',
    p: 4,
}

// ==============================|| ORDER TABLE ||============================== //
const FabricToVendorMapping = () => {
    const [rows, setRows] = useState([]);
    const cookies = new Cookies();
    const [buffer, setBuffer] = useState(false);
    const [error, setError] = useState(null);
    const [rowSelectionModel, setRowSelectionModal] = useState([]);
    const [refreshFabricVendorMappingView, setRefreshFabricVendorMappingView] = useState(false);
    const [addNewRow, setAddNewRow] = useState([
        { ...fabricVendorMappingSchema, createdBy: cookies.get("id") }
    ]);
    const [snackBar, setSnackBar] = useState("");
    const columns = [
        {
            id: 'id',
            field: 'id',
            align: 'left',
            disablePadding: false,
            headerName: 'Id',
            key: 'id',
            label: 'Id',
            width: 100,
        },
        {
            id: 'fabric_id',
            field: 'fabric_id',
            align: 'left',
            disablePadding: false,
            headerName: 'Fabric Id',
            key: 'fabric_id',
            label: 'Fabric Id',
            width: 100,
        },
        {
            id: 'internal_id',
            field: 'internal_id',
            align: 'left',
            disablePadding: false,
            headerName: 'internal_id',
            key: 'internal_id',
            label: 'Internal',
            width: 150,
        },
        {
            id: 'name',
            field: 'name',
            align: 'left',
            disablePadding: false,
            headerName: 'Vendor Name',
            key: 'name',
            label: 'name',
            width: 300,
        },
        {
            id: 'rate',
            field: 'rate',
            align: 'left',
            disablePadding: false,
            headerName: 'Rate',
            label: 'rate',
            width: 100,
        },
        {
            id: 'moq',
            field: 'moq',
            align: 'left',
            disablePadding: false,
            headerName: 'Moq',
            label: 'moq',
            width: 100,
        },

    ];

    const fetchFabricVendorMapping = async () => {
        try {
            setBuffer(true);
            let url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/vendorMaster/details/fabricToVendorMapping`;
            const requestOptions = {
                method: 'GET',
                headers: { "Content-Type": "application/json" },
                redirect: 'follow',
            };
            const res = await fetch(url, requestOptions);

            if (res.ok) {
                let data = await res.json();
                setRows(data);

            }
            if (!res.ok) {
                setError('Error in displaying fabricDetails data');
            }
            setBuffer(false);
        }
        catch (error) {
            setError('Error fetching  fabricDetails data')
            console.error(error);
        }
    }

    useEffect(() => {
        (async () => {
            await fetchFabricVendorMapping();
        })()
    }, [])
    return (
        <Paper>
            <MDBox>
                <Snackbar
                    open={snackBar != 0}
                    autoHideDuration={1500}
                    onClose={() => {
                        setSnackBar(0)
                    }}
                    // message="test"
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity={snackBar == 1 ? 'success' : snackBar == 2 ? 'error' : ''} >{snackBar == 1 ? 'New FabricIds Inserted Successfully' : snackBar == 2 ? 'failed to create  new FabricIds' : ''}</Alert>
                </Snackbar>
                <AddNewFabricToVendorMapping addNewRow={addNewRow} setAddNewRow={setAddNewRow} setSnackBar={setSnackBar} rows={rows} refreshFabricVendorMappingView={refreshFabricVendorMappingView} setRefreshFabricVendorMappingView={setRefreshFabricVendorMappingView} />
                <div style={{ display: 'flex', flexDirection: 'column', gap: "0.5rem" }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: "0.5rem" }}>
                        <div style={{ height: "56.875rem", width: '100%' }}>
                            {buffer && <div style={{
                                textAlign: 'center',
                                padding: '20vh 0vh',
                                marginLeft: '50%'
                            }}>
                                <CircularProgress />
                            </div>}
                            {!buffer && (error == null) &&
                                <DataGrid
                                    sx={{ height: '100%' }}
                                    rows={rows}
                                    rowHeight={50}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 50 },
                                        },
                                    }}
                                    onRowSelectionModelChange={(newRowSelectionModel) => {
                                        setRowSelectionModal(newRowSelectionModel)
                                    }}
                                    rowSelectionModel={rowSelectionModel}
                                    pageSizeOptions={[50, 75, 100]}

                                />

                            }
                            {!buffer && (error != null) && <Alert severity='error' dismissible='true'>{error}</Alert>}
                        </div>
                    </div>
                </div>
            </MDBox>
        </Paper >
    );
}
export default FabricToVendorMapping;