//

// Material Dashboard 2 React base styles
import colors from "../../../../assets/theme/base/colors";

// Material Dashboard 2 React helper functions
import pxToRem from "../../../../assets/theme/functions/pxToRem";

const { transparent } = colors;

const select = {
  
};

export default select;
