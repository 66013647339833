import React, { useRef, useState, useEffect } from 'react';
import html2pdf from 'html2pdf.js';
import {
  Modal,
  Button,
  Box,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import MDButton from "../../components/MDButton";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  height: '80%', // Set a fixed height
  overflow: 'auto',
  boxShadow: 24,
  p: 4,
};
const currentYear = new Date().getFullYear().toString().slice(-2);
const nextYear = (new Date().getFullYear() + 1).toString().slice(-2);
const PdfGenerator = ({ data, vendorData, purchaseOrderMetaData, isModalOpen, setIsModalOpen }) => {
  const ref = useRef();

  const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between'
  };

  const tableStyle = {
    width: '47%',
    fontSize: '13px'
  };

  const leftTableStyle = {
    float: 'left'
  };

  const rightTableStyle = {
    float: 'right'
  };

  const generatePDF = () => {
    const input = ref.current;
    html2pdf(input)
      .from(input).set({
        filename: `purchase-order-${new Date().getTime()}` + '.pdf',
        pagebreak: {
          mode: 'avoid-all',
        },
        jsPDF: { orientation: 'portrait', unit: 'in', format: 'letter', compressPDF: true }
      }).save();
  }
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const reloadPage = () => {
    window.location.reload();
  }

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(generatePDF);
      setTimeout(reloadPage, 1000)
    }
  }, [isModalOpen]);


  const InvoiceDetailsTable = ({ data, label }) => {
    return (
      <TableContainer component={Table} style={{ width: '100%' }}>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '13px', border: '1px solid #000', padding: '4px', paddingTop: 0, paddingBottom: 0 }} colSpan={Object.keys(row).length} >
                {row}
              </TableCell>
            </TableRow>


          ))}
          <TableRow key='ind'>
            <TableCell sx={{ border: '1px solid #000', padding: '10px' }} >

            </TableCell>
          </TableRow>
        </TableBody>
      </TableContainer>
    );
  };
  const VendorDetailsTable = ({ data }) => {
    return (
      <TableContainer component={Table} style={{ fontSize: '13px', border: '1px solid #000', width: '47%' }}>
        <TableBody>
          <TableRow sx={{ border: '1px solid #000' }}>
            <TableCell sx={{ padding: '10px' }}>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} style={{ fontSize: '13px', border: '1px solid #000', padding: '4px', paddingTop: 0, paddingBottom: 0 }}>
              vendor details
            </TableCell>
          </TableRow>
        </TableBody>
        <TableBody>
          {data[0] ? (
            Object.keys(data[0]).map((key, index) => (
              <TableRow key={index}>
                <TableCell style={{ border: '1px solid #000', padding: '4px', paddingTop: 0, paddingBottom: 0 }}>{key}</TableCell>
                <TableCell style={{ border: '1px solid #000', padding: '4px', paddingTop: 0, paddingBottom: 0 }}>{data[0][key]}</TableCell>
              </TableRow>
            ))
          ) : (
            <></>
          )}
          <TableRow sx={{ border: '1px solid #000' }}>
            <TableCell sx={{ padding: '10px' }}>
            </TableCell>
          </TableRow>
        </TableBody>
      </TableContainer>
    );
  };
  const LeftTable = ({ data, purchaseOrderMetaData }) => {
    return (

      <div className='random'>

        <TableBody style={{ border: '1px solid #000' }}>
          <TableRow sx={{ width: '100%' }} key={0}>
            <TableCell size='small' width='47%' style={{ border: '1px solid #000', fontSize: '13px', fontWeight: 'bold', padding: 0, paddingRight: '59.5px' }}>
              {data[0]}
            </TableCell>
            <TableCell size='small' style={{ width: '12.5%', border: '1px solid #000', padding: '2px' }}>
            </TableCell>
            <TableCell size='small' style={{ width: '28%', border: '1px solid #000', padding: '2px' }}>
            </TableCell>
            <TableCell size='small' style={{ width: '12.5%', border: '1px solid #000', padding: '2px' }}>
            </TableCell>
          </TableRow>
        </TableBody>
        <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', border: 'none' }}>
          <div style={{ fontSize: '13px', border: '1px solid #000', width: '47%' }}>
            <div style={{ fontSize: '13px', border: '1px solid #000' }}>{data[1]}</div>
            <div style={{ fontSize: '13px', border: '1px solid #000' }}>{data[2]}</div>
            <div style={{ fontSize: '13px', border: '1px solid #000' }}>
              {data[3]}
            </div>
          </div>
          <RightTable data={purchaseOrderMetaData} />
        </div>


      </div>

    );
  };
  const RightTable = ({ data }) => {
    return (
      <TableContainer component={Table} style={{ ...tableStyle, width: '30%' }}>
        <Table>
          <TableBody>
            {data[0] ? (
              Object.keys(data[0]).map((key, index) => (
                <TableRow key={index}>
                  <TableCell size='small' style={{ border: '1px solid #000', fontWeight: 'bold', padding: '4px' }}>{key}</TableCell>
                  {[data[0]].map((item, rowIndex) => (
                    <TableCell key={rowIndex} style={{ fontSize: '13px', border: '1px solid #000', padding: '4px' }}>
                      {index === 0 ? `FS_PO_${currentYear}_${nextYear}_${item[key]}` : item[key]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const PurchaseOrderCommentsTable = ({ data }) => {
    return (
      <div>
        <TableBody style={{ border: '1px solid #000' }}>

          <TableRow key={0}>
            <TableCell colSpan="13" style={{ width: '47%', fontSize: '13px', border: '1px solid #000', padding: '4px' }}>
              {data[0]}
            </TableCell>
          </TableRow>
        </TableBody>
        <div style={{ fontSize: '13px' }}>{data[1]}</div>
        <div style={{ fontSize: '13px' }}>{data[2]}</div>
        <div style={{ fontSize: '13px' }}>{data[3]}</div>
      </div>
    );
  };
  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: -1, opacity: 0, pointerEvents: 'none' }}
      >
        <>

          <div ref={ref} style={{ width: '780px', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }} >
            {data?.map((value, i) => {
              let aggregatedDataMap = new Map();
              let dataCopy = structuredClone(data[i]);
              let totalIgst = 0, totalCgst = 0, totalSgst = 0, totalAmount = 0;
              dataCopy?.forEach(entry => {
                totalIgst += entry?.igst;
                totalCgst += entry?.cgst;
                totalSgst += entry?.sgst;
                totalAmount += entry["amount"];
                delete entry["igst"];
                delete entry["cgst"];
                delete entry["sgst"];

                let fabricId = entry["Fabric_ID"];
                if (aggregatedDataMap.has(fabricId)) {
                  let existingEntry = aggregatedDataMap.get(fabricId);
                  existingEntry.quantity += entry["quantity"];
                  existingEntry.amount += entry["amount"];

                } else {
                  aggregatedDataMap.set(fabricId, { ...entry });
                }
              });

              return <div key={i} style={{ border: '1px solid #000', marginTop: '1rem', marginBottom: '9rem' }}>
                <Table>
                  <TableBody >
                    <TableRow style={{ border: '1px solid #000' }}>
                      <TableCell>
                        <h1 style={{ textAlign: 'center' }}>PURCHASE ORDER</h1>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <LeftTable data={['Fable Street Lifestyle Solutions Private Limited', 'Plot No 362, Phase-II', 'Udyog Vihar, Gurgaon', 'Haryana-122016']} purchaseOrderMetaData={purchaseOrderMetaData[i]} />
                <div>
                  <VendorDetailsTable data={vendorData[i]} />
                </div>

                <div style={{ ...containerStyle, alignItems: 'flex-end' }}>
                  <TableContainer component={Box} style={{ ...tableStyle, ...leftTableStyle }}>
                    <InvoiceDetailsTable
                      data={[
                        'Invoice To:',
                        'Fable Street Lifestyle Solutions Private Limited',
                        'Plot No 362, Phase-II',
                        'Udyog Vihar, Gurgaon',
                        'Haryana-122016',
                        'GSTIN NO-06AACCF7606C1Z1',
                        'Email:- accounts@fablestreet.com',
                      ]}
                    />
                  </TableContainer>

                  <TableContainer component={Box} style={{ ...tableStyle, ...rightTableStyle }}>
                    <InvoiceDetailsTable
                      data={[
                        'Ship To:',
                        'Fable Street Lifestyle Solutions Private Limited',
                        'Haryana-122016',
                        'GSTIN NO-06AACCF7606C1zi',
                        'Email:- accounts@fablestreet.com',
                      ]}
                    />
                  </TableContainer>
                </div>

                <TableContainer>
                  <Table style={{ fontSize: '13px', marginBottom: '4px' }}>
                    <TableHead>

                      <TableRow>
                        {dataCopy[0] ? (
                          Object.keys(dataCopy[0]).map((key, index) => (
                            <TableCell key={index} sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}>{key}</TableCell>
                          ))
                        ) : (
                          <></>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ border: '1px solid #000' }}>
                      {Array.from(aggregatedDataMap.values())?.map((item, index) => (
                        <TableRow key={index}>
                          {Object.values(item).map((value, index) => (
                            <TableCell key={index} sx={{ margin: 0, fontWeight: 'bold', paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}>{value}</TableCell>
                          ))}
                        </TableRow>

                      ))}

                      {vendorData[i][0]?.inHaryana ?
                        <>
                          <><TableRow>
                            <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}></TableCell>
                            <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}></TableCell>
                            <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}></TableCell>
                            <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}></TableCell>

                            <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}>CGST</TableCell>
                            <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}>2.5%</TableCell>
                            <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}>{totalCgst}</TableCell>
                          </TableRow>
                            <TableRow>
                              <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}></TableCell>
                              <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}></TableCell>
                              <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}></TableCell>
                              <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}></TableCell>
                              <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}>SGST</TableCell>
                              <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}>2.5%</TableCell>
                              <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}>{totalSgst}</TableCell>
                            </TableRow>
                          </>
                        </> :
                        <><TableRow>
                          <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}></TableCell>
                          <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}></TableCell>
                          <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}></TableCell>
                          <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}></TableCell>
                          <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}>IGST</TableCell>
                          <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}>5%</TableCell>
                          <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}>{totalIgst}</TableCell>
                        </TableRow>
                        </>
                      }


                      <TableRow >
                        <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}></TableCell>
                        <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}></TableCell>
                        <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}>Total</TableCell>
                        <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}></TableCell>
                        <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}></TableCell>
                        <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}></TableCell>
                        <TableCell sx={{ margin: 0, paddingTop: 0, paddingBottom: 0, border: '1px solid black' }}>{vendorData[i][0]?.inHaryana ? totalAmount + totalCgst + totalSgst : totalAmount + totalIgst}</TableCell>
                      </TableRow>

                    </TableBody>
                  </Table>
                </TableContainer>
                <div className='before'>
                  <PurchaseOrderCommentsTable
                    data={[
                      'Purchase Order Comments',
                      '1. Payment to be made 30 days after delivery, subject to quality approval.',
                      '2. Rate includes delivery at our premises (landed cost).',
                      '3. Please ensure that right HSN Code is mentioned in the invoice and appropriate tax is charged as per law.',
                    ]}
                  />
                  <div style={{ borderTop: '2px solid black', borderBottom: '2px solid black', padding: '10px' }}>
                    <Typography variant="body1" style={{ fontSize: '13px', textAlign: 'center' }}>
                      Please sign below and return acknowledgment of this purchase order. Please notify us immediately if you are unable to supply.
                    </Typography>
                  </div>
                  <h4 style={{ fontSize: '13px', textAlign: 'end', marginRight: '10px' }}>For Fable Street Lifestyle Solutions Pvt Ltd</h4>
                  <h4 style={{ fontSize: '13px', textAlign: 'end', marginRight: '10px' }}>Authorised Signatory</h4>
                </div>
              </div>
            })
            }

          </div>

        </>
      </Modal>
    </>
  );
}

export default PdfGenerator;
