import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { CSVLink } from "react-csv";
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PDFGenerator from '../../pdfGenerator/pdfGenerator'
import DropDown from '../../dropDown/dropDown';
import Cookies from 'universal-cookie';
import Chip from '@mui/material/Chip';
import {
    LinearProgress, TextField, Paper, Alert, Button,
    FormControlLabel, Checkbox, Autocomplete, Tooltip
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDButton from '../../../components/MDButton';
import MDBox from '../../../components/MDBox';
import dayjs from 'dayjs';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    bgcolor: 'background.paper',
    p: 4,
}

// ==============================|| ORDER TABLE ||============================== //
function PurchaseOrderView() {
    const [rows, setRows] = useState([]);
    // console.log(rows);
    const cookies = new Cookies();
    const [buffer, setBuffer] = useState(false);
    const [error, setError] = useState(null);
    const [vendors, setVendors] = useState([]);
    const [filterVendors, setFilterVendors] = useState([]);
    const [csvReport, setCsvReport] = useState({ data: [], headers: [], filename: 'purchaseOrder.csv' });
    const [filterModal, setFilterModal] = useState(false);
    const [rowSelectionModel, setRowSelectionModal] = useState([]);
    const [rowEditMode, setRowEditMode] = useState({});
    const [editSuccess, setEditSuccess] = useState(false);
    const [PoDialogForClose, setPoDialogForClose] = useState(false);
    const [PoDialogForCancel, setPoDialogForCancel] = useState(false);
    const [poPdfDownload, setPoPdfDownload] = useState('');
    const inputRef = useRef(null);
    const [expectedDeliveryDateEditRows, setExpectedDeliveryDateEditRows] = useState([]);
    const [selectedPoStatus, setSelectedPoStatus] = useState([0]);
    const [isSelectedPoStatusChanged, setIsSelectedPoStatusChanged] = useState(false);
    const [fabricIdFilterData, setFabricIdFilterData] = useState([]);
    const [poFilterData, setPoFilterData] = useState([]);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [selectReturnFabricId, setSelectReturnFabricId] = useState(null);
    const [selectPurchaseCode, setSelectPurchaseCode] = useState(null);
    const [expectedDeliveryDateStart, setExpectedDeliveryDateStart] = useState(null);
    const [expectedDeliveryDateEnd, setExpectedDeliveryDateEnd] = useState(null);
    const [selectVendorValue, setSelectVendorValue] = useState("");
    const [filteredRows, setFilteredRows] = useState([]);
    const [purchaseOrderMetaData, setPurchaseOrderMetaData] = useState([])
    const [vendorData, setVendorData] = useState([])
    const [data, setData] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleCheckboxChange = (value) => {
        setIsSelectedPoStatusChanged(true);
        setSelectedPoStatus((prevSelectedPoStatus) => {
            if (prevSelectedPoStatus.includes(value)) {
                return prevSelectedPoStatus.filter((item) => item !== value);
            } else {
                return [...prevSelectedPoStatus, value];
            }
        });
    };
    const columns = [
        {
            id: 'vendorId',
            field: 'vendorId',
            align: 'left',
            disablePadding: false,
            headerName: 'vendor Name',
            key: 'vendorName',
            label: 'vendorName',
            width: 200,
            renderCell: (params) => {
                let vendorName = vendors
                    .map((item) => {
                        return item.key === params.value ? item.label : '';
                    })
                    .filter((value) => value !== '');
                return (vendorName[0])
            }
        },
        {
            id: 'returnFabricId',
            field: 'returnFabricId',
            align: 'left',
            disablePadding: false,
            headerName: 'Fabric Id',
            key: 'returnFabricId',
            label: 'Fabric Id',
            width: 200
        },

        {
            id: 'issuanceFabricId',
            field: 'issuanceFabricId',
            align: 'left',
            disablePadding: false,
            headerName: 'Issuance FabricId',
            key: 'issuanceFabricId',
            label: 'issuanceFabricId',
            width: 200
        },
        {
            id: 'purchaseCode',
            field: 'purchaseCode',
            align: 'left',
            disablePadding: false,
            headerName: 'Purchase Code',
            key: 'purchaseCode',
            label: 'Purchase Code',
            width: 200,
            renderCell: (params) => {
                const currentYear = new Date(params?.row?.createdAt).getFullYear().toString().slice(-2);
                const nextYear = (new Date(params?.row?.createdAt).getFullYear() + 1).toString().slice(-2);
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>

                        <div>
                            FS_PO_{currentYear}_{nextYear}_{params.value}
                        </div>
                        <FileDownloadIcon fontSize='medium' poCode={params.value} onClick={(e) => {
                            setPoPdfDownload('' + params?.row?.purchaseCode)
                        }} />
                    </div>
                )
            },
        },
        {
            id: 'status',
            field: 'status',
            align: 'left',
            disablePadding: false,
            headerName: 'Status',
            key: 'status',
            label: 'status',
            width: 200,
            renderCell: (params) => (
                <Chip
                    label={params.value === 0 ? 'open' : params.value === 1 ? "closed" : params.value === 2 ? "cancelled" : params.value === 3 ? "autoCancelled" : params.value}
                    color={params.value === 0 ? 'info' : params.value === 1 ? "success" : params.value === 2 ? "error" : params.value === 3 ? "warning" : params.value}
                    variant="outlined"
                    sx={
                        {
                            fontWeight: 'bold',
                            borderWidth: '0.13rem',
                            borderStyle: 'solid',
                        }
                    }
                />
            ),
        },
        {
            id: 'quantity',
            field: 'quantity',
            align: 'left',
            disablePadding: false,
            headerName: 'Quantity',
            key: 'quantity',
            label: 'Quantity',
            width: 200
        },
        {
            id: 'recievedQuantity',
            field: 'recievedQuantity',
            align: 'left',
            disablePadding: false,
            headerName: 'Grn Qty',
            key: 'grnQty',
            label: 'grnQty',
            width: 200
        },

        {
            id: 'rate',
            field: 'rate',
            align: 'left',
            disablePadding: false,
            headerName: 'Rate',
            key: 'rate',
            label: 'rate',
            width: 200
        },
        {
            id: 'amount',
            field: 'amount',
            align: 'left',
            disablePadding: false,
            headerName: 'Amount',
            key: 'amount',
            label: 'Amount',
            width: 200
        },
        {
            id: 'expectedDeliveryDate',
            field: 'expectedDeliveryDate',
            align: 'left',
            disablePadding: false,
            headerName: 'Expected Delivery Date',
            key: 'expectedDeliveryDate',
            label: 'Expected Delivery Date',
            width: 200,
            renderCell: (obj) => (
                <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={dayjs(obj.row.expectedDeliveryDate)}
                            format="DD-MM-YYYY"
                            onChange={(e) => {
                                const newDate = e;
                                const updatedRows = [...rows];
                                updatedRows[obj.row.sno].expectedDeliveryDate = newDate;
                                setExpectedDeliveryDateEditRows([...expectedDeliveryDateEditRows, { "purchaseOrderId": obj.row.purchaseOrderId, "expectedDeliveryDate": newDate }])
                            }}
                        />
                    </LocalizationProvider>
                </>
            ),
        }
    ];

    const handleExportClick = () => {
        inputRef.current.click();
    }
    const fetchVendorList = async () => {
        const url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/purchase/details/vendorList`;
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "access-token": await cookies.get('jwt'),
                "refresh-token": await cookies.get('refresh')
            },
        }
        const res = await fetch(url, requestOptions);
        if (res.ok) {
            let response = await res.json();
            console.log(response,'res')
            let vendorsObj = {};

            for (let i = 0; i < response.length; i++) {
                const vendorName = response[i].vendorName;
                const vendorInternalId = response[i].vendorInternalId;
                const fabricInternalId = response[i].fabricInternalId;
                const inHaryana = response[i].inHaryana;
                const vendorMasterId = response[i].vendor_master_id;
                const fabricMasterID = response[i].fabric_master_id;
                if (vendorsObj[vendorInternalId]) {
                    vendorsObj[vendorInternalId].fabricIds.push({ fabricInternalId, fabricMasterID });
                }
                else {
                    vendorsObj[vendorInternalId] = {
                        "fabricIds": [{ fabricInternalId, fabricMasterID }],
                        "vendorName": vendorName,
                        "vendorMasterId": vendorMasterId,
                        "inHaryana": inHaryana
                    }
                }


            }

            let vendorsList = [];
            for (let key in vendorsObj) {
                vendorsList.push(
                    {
                        label: vendorsObj[key].vendorName,
                        key: vendorsObj[key].vendorMasterId,
                        value: vendorsObj[key].vendorMasterId,
                    }
                )
            }
            setVendors(vendorsList);
            setFilterVendors(vendorsList)
        }
        if (!res.ok) {
            setError("Error in fetching Vendors list");
        }
    }

    const handleCloseOrder = async (row) => {
        let url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/purchase/update/purchaseOrderStatus?purchaseOrderId=${rowSelectionModel}`;
        const requestOptions = {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "access-token": await cookies.get('jwt'),
                "refresh-token": await cookies.get('refresh')
            },

            redirect: 'follow',
            body: JSON.stringify({
                "status": 1
            }),

        };
        const res = await fetch(url, requestOptions);
        setPoDialogForClose(false);
        setEditSuccess(`Selected Purchase Order has been closed`);
        setRowSelectionModal([]);
        await fetchOpenPurchaseOrder({ status: selectedPoStatus });
    }
    const handleCancelOrder = async (row) => {
        let url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/purchase/update/purchaseOrderStatus?purchaseOrderId=${rowSelectionModel}`;
        const requestOptions = {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "access-token": await cookies.get('jwt'),
                "refresh-token": await cookies.get('refresh')
            },
            redirect: 'follow',
            body: JSON.stringify({
                "status": 2
            })
        };
        await fetch(url, requestOptions);
        setPoDialogForCancel(false);
        setEditSuccess(`Selected Purchase Order has been cancelled`);
        setRowSelectionModal([]);
        await fetchOpenPurchaseOrder({ status: selectedPoStatus });
    }

    const handlePoPdfDownload = async (poCode) => {
        let url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/purchase/details/singlePurchaseOrder`;
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                // "access-token": await cookies.get('jwt'),
                // "refresh-token": await cookies.get('refresh')
            },
            redirect: 'follow',
            body: JSON.stringify({
                "singlePoCode": poCode
            })
        };
        const res = await fetch(url, requestOptions);
        const result = await res.json();
        const purchaseOrderData = result.map((po, SR_No) => {
            const Fabric_ID = po.returnFabricId
            const Product_Name = po.fabricName
            const color = po.color
            const quantity = po.quantity
            const rate = po.rate
            const amount = po.amount
            const igst = po?.igst
            const cgst = po?.cgst
            const sgst = po?.sgst
            SR_No += 1
            return {
                SR_No,
                Fabric_ID,
                Product_Name,
                color,
                quantity,
                rate,
                amount,
                igst,
                cgst,
                sgst
            }
        })
        const vendorInfo = result.map(vm => {
            const vendorName = vm.vendorName
            const vendorGst = vm.gst
            const vendorPhoneNo = vm.phone_number
            const vendorEmail = vm.email
            const vendorAddress = vm.address
            const vendorCity = vm.city
            const vendorPincode = vm.pincode
            const inHaryana = vm.in_haryana

            return {
                vendorName,
                inHaryana,
                vendorGst,
                vendorPhoneNo,
                vendorEmail,
                vendorAddress,
                vendorCity,
                vendorPincode
            }
        })
        const fetchPurchaseOrderMetaData = result.map(po => {
            const PO = po.purchaseCode
            const date = moment
                .utc(po.createdAt)
                .add(5, 'hours')
                .add(30, 'minutes')
                .format('DD/MM/YYYY')
            return { PO, date }
        })
        setPurchaseOrderMetaData([[...fetchPurchaseOrderMetaData]])
        setVendorData([[...vendorInfo]])
        setData([[...purchaseOrderData]])
        setIsModalOpen(true)

        setPoPdfDownload('');
        setEditSuccess(`Selected Purchase Order PDF downloaded successfully`);
        setRowSelectionModal([]);
        await fetchOpenPurchaseOrder({ status: selectedPoStatus });
    }

    const handleEditExpectedDeliveryDate = async () => {
        let url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/purchase/update/purchaseOrderEstimateDate`;
        const requestOptions = {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "access-token": await cookies.get('jwt'),
                "refresh-token": await cookies.get('refresh')
            },
            redirect: 'follow',
            body: JSON.stringify({
                "modifiedExpectedDeliveryDates": expectedDeliveryDateEditRows
            })
        };
        const output = await fetch(url, requestOptions);
        setRowEditMode({});
        setExpectedDeliveryDateEditRows([]);
        setRowSelectionModal([]);
        setEditSuccess("Expected Delivery Date has been updated");
        await fetchOpenPurchaseOrder({ status: selectedPoStatus });
    }
    const handleFilterReset = async () => {
        setSelectReturnFabricId('');
        setSelectPurchaseCode('')
        setExpectedDeliveryDateStart(null);
        setExpectedDeliveryDateEnd(null)
        setSelectedPoStatus([0])
        setIsFilterApplied(false);
        setFilteredRows([]);
        if (selectVendorValue) (setSelectVendorValue(null));
        await fetchOpenPurchaseOrder({ status: selectedPoStatus });

    }

    const handleFilterSubmit = async () => {
        let currentFilteredRows = [];
        if ((isFilterApplied && isSelectedPoStatusChanged)) {

            await fetchOpenPurchaseOrder({
                "returnFabricId": selectReturnFabricId,
                "purchaseOrderCode": selectPurchaseCode ? selectPurchaseCode.split('_')[4] : null,
                "expectedDeliveryDateStart": expectedDeliveryDateStart,
                "expectedDeliveryDateEnd": expectedDeliveryDateEnd,
                "vendorMasterId": selectVendorValue || null,
                "status": selectedPoStatus
            });
        }
        else {
            currentFilteredRows = rows.filter(row => {
                if ((!selectReturnFabricId || row.returnFabricId == selectReturnFabricId) &&
                    (!selectPurchaseCode || row.purchaseCode == (selectPurchaseCode.split('_')[4])) &&
                    (!expectedDeliveryDateStart || row.expectedDeliveryDateStart === expectedDeliveryDateStart) &&
                    (!expectedDeliveryDateEnd || row.expectedDeliveryDateEnd === expectedDeliveryDateEnd) &&
                    (!selectVendorValue || row.vendorId === selectVendorValue))
                    return row;

            });
        }
        setFilteredRows(currentFilteredRows);
    }
    const fetchOpenPurchaseOrder = async (filters) => {
        setBuffer(true);
        let url = `${process.env.REACT_APP_SERVER_URL}/v0/fms/api/purchase/details/purchaseOrder`;
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "access-token": await cookies.get('jwt'),
                "refresh-token": await cookies.get('refresh')
            },
            redirect: 'follow',
            body: JSON.stringify({
                "filters": {
                    "returnFabricId": filters && filters.returnFabricId ? filters.returnFabricId : null,
                    "purchaseCode": filters && filters.purchaseOrderCode ? filters.purchaseOrderCode : null
                },
                "vendorFilter": {
                    "vendorMasterId": filters && filters.vendorMasterId !== "" ? filters.vendorMasterId : null
                },
                "dateFilters": {
                    "expectedDeliveryDate": filters ? [filters.expectedDeliveryDateStart ? filters.expectedDeliveryDateStart : null, filters.expectedDeliveryDateEnd ? filters.expectedDeliveryDateEnd : null] : [null, null]
                },
                "statusFilters": {
                    "status": filters && filters.status !== undefined ? filters.status : null
                }
            })
        };

        const res = await fetch(url, requestOptions);

        if (res.ok) {
            let data = await res.json();
            data = data.map((item, index) => { return { ...item, "id": data[index].purchaseOrderId, "sno": index } })
            setCsvReport({
                data: data,
                headers: columns,
                filename: `purchaseOrder-${moment(new Date()).format('DD-MM-YYYY')}.csv`
            });
            setRows(data);
            const filterDataset = [... new Set(data.map(item => item.returnFabricId))];
            const initialFabricDataset = [... new Set(rows?.map(item => item.returnFabricId))]
            const filterPoDataset = [... new Set(data.map(item => { return { purchaseCode: item.purchaseCode, createdAt: item.createdAt } }))];
            const initialPoDataset = [... new Set(rows?.map(item => item.purchaseCode))];

            if (initialFabricDataset.length === 0 || initialFabricDataset.length < filterDataset.length || (isSelectedPoStatusChanged)) {
                setFabricIdFilterData(filterDataset);
            }

            if (initialPoDataset.length == 0 || initialPoDataset.length < filterPoDataset.length || (isSelectedPoStatusChanged))
                setPoFilterData(filterPoDataset)
            if (isSelectedPoStatusChanged)
                setIsSelectedPoStatusChanged(false);
            setError(null);
        }
        if (!res.ok) {
            setError('Error in displaying purchase order data');
        }
        setBuffer(false);
    }

    useEffect(() => {
        (async () => {
            await fetchOpenPurchaseOrder({ status: selectedPoStatus });
        })();
        fetchVendorList();
    }, [])

    useEffect(() => {
        setIsFilterApplied(false);
        setFilteredRows([]);
        setSelectReturnFabricId('');
        setSelectPurchaseCode('');
        setSelectVendorValue('');
        (async () => {
            await fetchOpenPurchaseOrder(
                {
                    "status": selectedPoStatus
                }
            );
        })();
    }, [selectedPoStatus])

    useEffect(() => {
        let filterDataList = structuredClone(rows);
        if (selectVendorValue) {
            filterDataList = rows.filter(obj => obj.vendorId == selectVendorValue);
            const newFilterPoDataset = [...new Set(filterDataList.map(item => {
                return { purchaseCode: item.purchaseCode, createdAt: item.createdAt }
            }))];
            setPoFilterData(newFilterPoDataset)
            const newFabricFilterDataset = [...new Set(filterDataList.map(item => {
                return item.returnFabricId;
            }))];
            setFabricIdFilterData(newFabricFilterDataset)
        }
        else {

            const newFilterPoDataset = [...new Set(rows.map(item => {
                return { purchaseCode: item.purchaseCode, createdAt: item.createdAt }
            }))];
            setPoFilterData(newFilterPoDataset)
            const newFabricFilterDataset = [...new Set(rows.map(item => {
                return item.returnFabricId;
            }))];
            setFabricIdFilterData(newFabricFilterDataset)
        }
        if (selectPurchaseCode) {
            filterDataList = filterDataList.filter(obj => obj.purchaseCode == parseInt(selectPurchaseCode.split('_')[4]));
            const newVendorsDataset = new Set(filterDataList.map(item => {
                const vendorMap = new Map();
                vendors.forEach(obj => {
                    vendorMap.set(obj.key, obj);
                });
                if (item.vendorId == vendorMap.get(item.vendorId)?.key) {
                    return vendorMap.get(item.vendorId);
                }
                return '';
            }));
            newVendorsDataset.delete('');
            setFilterVendors([...newVendorsDataset])
            const newFabricFilterDataset = new Set(filterDataList.map(item => {
                return item.returnFabricId;
            }));
            setFabricIdFilterData([...newFabricFilterDataset])
        }
        else {

            const newVendorsDataset = new Set(rows.map(item => {
                const vendorMap = new Map();
                vendors.forEach(obj => {
                    vendorMap.set(obj.key, obj);
                });
                if (item?.vendorId == vendorMap?.get(item.vendorId)?.key) {
                    return vendorMap.get(item.vendorId);
                }
                return '';
            }));
            setFilterVendors([...newVendorsDataset])
            const newFabricFilterDataset = new Set(rows.map(item => {
                return item.returnFabricId;
            }));
            setFabricIdFilterData([...newFabricFilterDataset])

        }
        if (selectReturnFabricId) {
            filterDataList = filterDataList.filter(obj => obj.returnFabricId == selectReturnFabricId);
            const newVendorsDataset = [...new Set(filterDataList.map(item => {
                const vendorMap = new Map();
                vendors.forEach(obj => {
                    vendorMap.set(obj.key, obj);
                });
                if (item.vendorId == vendorMap.get(item.vendorId)?.key) {
                    return vendorMap.get(item.vendorId);
                }
                return '';
            }))];
            setFilterVendors(newVendorsDataset)
            const newFilterPoDataset = new Set(filterDataList.map(item => {
                return { purchaseCode: item.purchaseCode, createdAt: item.createdAt }
            }))
            setPoFilterData([...newFilterPoDataset])
        }
        else {
            const newVendorsDataset = [...new Set(rows.map(item => {
                const vendorMap = new Map();
                vendors.forEach(obj => {
                    vendorMap.set(obj.key, obj);
                });
                if (item?.vendorId == vendorMap?.get(item.vendorId)?.key) {
                    return vendorMap.get(item.vendorId);
                }
                return '';
            }))];
            setFilterVendors(newVendorsDataset)
            const newFilterPoDataset = new Set(rows.map(item => {
                return { purchaseCode: item.purchaseCode, createdAt: item.createdAt }
            }))
            setPoFilterData([...newFilterPoDataset])
        }
    }, [selectPurchaseCode, selectVendorValue, selectReturnFabricId]);

    const handleAlertClose = () => {
        setEditSuccess(false);
    };

    if (editSuccess)
        setTimeout(handleAlertClose, 4000);
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Dialog
                open={PoDialogForClose}
                onClose={() => { setPoDialogForClose(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to close the selected Purchase Orders?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setPoDialogForClose(false) }}>No</Button>
                    <Button onClick={handleCloseOrder} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={PoDialogForCancel}
                onClose={() => { setPoDialogForCancel(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to cancel the selected Purchase Orders?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setPoDialogForCancel(false) }}>No</Button>
                    <Button onClick={handleCancelOrder} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={poPdfDownload?.length}
                onClose={() => { setPoPdfDownload('') }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want pdf for the selected Purchase Order?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setPoPdfDownload('') }}>No</Button>
                    <Button onClick={(e) => handlePoPdfDownload(poPdfDownload)} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <MDBox >
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, marginBottom: 5, gap: '1rem' }} >
                    <div style={{ display: 'flex', justifyContent: "flex-end", gap: "0.3rem", marginRight: 10 }}>
                        {rowSelectionModel.length > 0 &&
                            <>
                                <MDButton color="warning" size="medium" variant="outlined" disabled={rowSelectionModel.length === 0} onClick={() => { setPoDialogForClose(true) }}>Close</MDButton>
                                <MDButton color="error" size="medium" variant="outlined" disabled={rowSelectionModel.length === 0} onClick={() => { setPoDialogForCancel(true) }}> Cancel </MDButton>
                            </>
                        }
                        <Tooltip title="Please update atleast one expected delivery date" disableHoverListener={expectedDeliveryDateEditRows.length > 0}>
                            <div>
                                <MDButton color="info" size="medium" variant="outlined" disabled={expectedDeliveryDateEditRows.length === 0} onClick={handleEditExpectedDeliveryDate} > Save Expected Delivery Date </MDButton>
                            </div>
                        </Tooltip>
                        <MDButton color="info" size="medium" variant="outlined" onClick={handleExportClick} ref={inputRef}>
                            <CSVLink {...csvReport} >Export</CSVLink>
                        </MDButton>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, gap: '0.8rem' }} >
                        <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: '0.6rem', paddingTop: '0.3rem', alignItems: 'center', gap: '2rem', overflowX: 'auto', whiteSpace: "nowrap" }} >
                            <MDBox style={{ minWidth: '8rem' }}>
                                <DropDown size='small' selectValue={selectVendorValue} setSelectValue={setSelectVendorValue} label="vendors" stores={filterVendors} />
                            </MDBox>
                            <Autocomplete
                                options={fabricIdFilterData}
                                size='small'
                                getOptionLabel={(option) => option}
                                value={selectReturnFabricId}
                                onChange={(event, newValue) => {
                                    setSelectReturnFabricId(newValue);
                                }}
                                renderInput={(params) =>
                                (<TextField
                                    {...params}
                                    label="Fabric Id"
                                    variant="outlined"
                                    placeholder='Fabric Id'
                                    sx={{ minWidth: '9em' }}
                                />
                                )
                                }
                            />
                            <Autocomplete
                                options={

                                    poFilterData.map((item) => `FS_PO_${new Date(item.createdAt).getFullYear().toString().slice(-2)}_${(new Date(item.createdAt).getFullYear() + 1).toString().slice(-2)}_${item.purchaseCode}`)}

                                size='small'
                                value={selectPurchaseCode}
                                onChange={(e, newValue) => {
                                    setSelectPurchaseCode(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        id="standard-required"
                                        {...params}
                                        label="Purchase Order Code"
                                        variant="outlined"
                                        placeholder='PO Code'
                                        style={{ minWidth: '14em' }}
                                    />
                                )}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.2rem', marginRight: '0.6rem' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} style={{ marginTop: 10 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
                                        <DatePicker
                                            label="Start Date"
                                            value={expectedDeliveryDateStart}
                                            onChange={(newVal) => {
                                                setExpectedDeliveryDateStart(newVal);
                                            }}
                                            format="YYYY-MM-DD"
                                            slotProps={{ textField: { size: 'small' } }}
                                            sx={{ padding: 0 }}
                                        />
                                        <DatePicker
                                            label="End Date"
                                            value={expectedDeliveryDateEnd}
                                            onChange={(newVal) => {
                                                setExpectedDeliveryDateEnd(newVal);
                                            }}
                                            format="YYYY-MM-DD"
                                            slotProps={{ textField: { size: 'small' } }}

                                        />
                                    </div>
                                </LocalizationProvider>
                            </div>
                        </div>

                    </div>
                </div>
            </MDBox>

            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 12, gap: "0.5rem" }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", marginLeft: 5, marginBottom: 10, overflowX: "auto" }}>
                    <FormControlLabel
                        control={<Checkbox checked={selectedPoStatus.includes(0)} onChange={() => handleCheckboxChange(0)} />}
                        label={<span style={{ fontSize: '14px', marginLeft: -8 }}>
                            Open
                        </span>}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={selectedPoStatus.includes(1)} onChange={() => handleCheckboxChange(1)} />}
                        label={<span style={{ fontSize: '14px', marginLeft: -8 }}>
                            Closed
                        </span>}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={selectedPoStatus.includes(2)} onChange={() => handleCheckboxChange(2)} />}
                        label={<span style={{ fontSize: '14px', marginLeft: -8 }}>
                            Cancelled
                        </span>}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={selectedPoStatus.includes(3)} onChange={() => handleCheckboxChange(3)} />}
                        label={<span style={{ fontSize: '14px', marginLeft: -8 }}>
                            Auto Cancelled
                        </span>}
                    />

                    <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', marginRight: '0.2rem' }} >
                        <MDButton color="info" variant="outlined" onClick={handleFilterReset} circular="true" sx={{
                            "color": "#00008B",
                            "border": '1px solid #00008B',
                        }}>
                            Reset
                        </MDButton>
                        <MDButton variant="contained" onClick={() => { setIsFilterApplied(true); handleFilterSubmit() }} circular="true" sx={{
                            "color": "white",
                            "border": '1px solid #00008B',
                            "background": "#00008B"
                        }}>
                            Apply
                        </MDButton>
                    </div>


                </div>
                {data?.length ?
                    <PDFGenerator
                        data={data}
                        vendorData={vendorData}
                        purchaseOrderMetaData={purchaseOrderMetaData}
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                    /> : <></>
                }
                {editSuccess &&
                    <div style={{ marginTop: 12 }}>
                        <Alert severity="success" onClose={handleAlertClose}>{editSuccess}</Alert>
                    </div>
                }

                <div style={{ height: '40.4rem', width: '100%' }}>
                    {buffer &&
                        <LinearProgress />
                    }
                    {!buffer && (error == null) &&
                        <DataGrid
                            rows={isFilterApplied ? filteredRows : rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 25 },
                                },
                            }}
                            checkboxSelection
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                setRowSelectionModal(newRowSelectionModel)
                            }}
                            rowSelectionModel={rowSelectionModel}
                            pageSizeOptions={[25, 50, 100]}
                        />
                    }
                    {!buffer && (error != null) && <Alert severity='error' dismissible='true'>{error}</Alert>}
                </div>
            </div>
        </Paper >
    );
}
export default PurchaseOrderView;