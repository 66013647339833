//

// Material Dashboard 2 React Base Styles
import colors from "../../../../assets/theme-dark/base/colors";
import borders from "../../../../assets/theme-dark/base/borders";
import typography from "../../../../assets/theme-dark/base/typography";

// Material Dashboard 2 React helper functions
import pxToRem from "../../../../assets/theme-dark/functions/pxToRem";
import rgba from "../../../../assets/theme-dark/functions/rgba";

const { inputBorderColor, info, grey, transparent, white } = colors;
const { borderRadius } = borders;
const { size } = typography;

const inputOutlined = {
  
};

export default inputOutlined;
